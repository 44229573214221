import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  studentInfo: {},
  tutorList: [],
  hiddenTutorsList: [],
  tutorDetails: {},
  profileInfo: {},
  tutorCourses: [],
  studentCourses: [],
  singleCourse: [],
  myCourses: [],
  myCourses: [],
  enrollStatus: [],
  allCourses: [],
  teacherPrioritySlot: [],
  studentBookSlot: [],
  videoCallData: {},
  studentSurveyComment: {},
  regularStudents: '',
  getProgressCounter:{},
  getAllTimeSlot:{},
  getStudentAttendanceRecord:{},
  getTwilioToken:{},
  videoEnableDisable:false,
  audioEnableDisable:false,
  studentCallLeave:0,
  messageObj:{},
  getStudentCategoryCertificate:{},
  studentPlanDetalis:{},
  student_upcoming_Slot:[],
  studentAnyMinutesPlan:[],
  setChatInfo:"",
  setChatRequestId :"",
  studentNotifications :""
};

const studentInfo = createSlice({
  name: 'studentInfo',
  initialState,
  reducers: {
    getStudentProfileInfo: (state, action) => {
      state.studentInfo = action.payload;
    },
    studentProfileInfo: (state, action) => {
      state.profileInfo = action.payload;
    },
    setTutorList: (state, action) => {
      state.tutorList = action.payload;
    },
    setRegularStudentList: (state, action) => {
      state.regularStudents = action.payload;
    },
    setRemoveFavouriteTutor: (state, action) => {
      const tutorList = state.tutorList.map((tut) => {
        if (tut._id === action.payload.tutorId) {
          return {
            ...tut,
            isLiked: !action.payload.isLiked,
          };
        }
        return tut;
      });
      state.tutorList = tutorList;
      state.tutorDetails =
        Object.keys(state.tutorDetails).length > 0
          ? {
              ...state.tutorDetails,
              isLiked: !action.payload.isLiked,
            }
          : {};
    },
    setTutorDetails: (state, action) => {
      state.tutorDetails = action.payload;
    },
    setTutorCourses: (state, action) => {
      state.tutorCourses = action.payload;
    },
    setShowHideTutor: (state, action) => {
      state.tutorDetails = {
        ...state.tutorDetails,
        ishideTutors: !action.payload.ishideTutors,
      };
    },
    setFollowUnfollowTutor: (state, action) => {
      state.tutorDetails = {
        ...state.tutorDetails,
        isFollow: !action.payload.isFollow,
      };
    },
    setAllCourses: (state, action) => {
      state.studentCourses = action.payload;
    },
    setSingleCourses: (state, action) => {
      state.singleCourse = action.payload;
    },
    setMyCourses: (state, action) => {
      state.myCourses = action.payload;
    },
    enrollCourseStatus: (state, action) => {
      state.enrollStatus = action.payload;
    },
    allCourses: (state, action) => {
      state.allCourses = action.payload;
    },
    teacherPrioritySlot: (state, action) => {
      state.teacherPrioritySlot = action.payload;
    },
    setHiddenTutorList: (state, action) => {
      state.hiddenTutorsList = action.payload;
    },
    studentBookSlot: (state, action) => {
      state.studentBookSlot = action.payload;
    },
    videoCallData: (state, action) => {
      state.videoCallData = action.payload;
    },
    studentSurveyComment: (state, action) => {
      state.studentSurveyComment = action.payload;
    },
    getProgressCounter: (state, action) => {
      state.getProgressCounter = action.payload;
    },
    getAllTimeSlot: (state, action) => {
      state.getAllTimeSlot = action.payload;
    },
    getStudentAttendanceRecord: (state, action) => {
      state.getStudentAttendanceRecord = action.payload;
    },

    getTwilioToken: (state, action) => {
      state.getTwilioToken = action.payload;
    },

    getStudentCategoryCertificate:(state, action) => {
      state.getStudentCategoryCertificate = action.payload;
    },

    getStudentCategoryCertificate:(state, action) => {
      state.getStudentCategoryCertificate = action.payload;

    },
    videoEnableDisable: (state, action) => {
      state.videoEnableDisable = action.payload;
    },
    audioEnableDisable: (state, action) => {
      state.audioEnableDisable = action.payload;
    },
    studentCallLeave: (state, action) => {
      state.studentCallLeave = action.payload;
    },
    studentPlanDetalis: (state, action) => {
      state.studentPlanDetalis = action.payload;
    },
    student_upcoming_Slot: (state, action) => {
      state.student_upcoming_Slot = action.payload;
    },
    studentAnyMinutesPlan: (state, action) => {
      state.studentAnyMinutesPlan = action.payload;
    },
    setMessageObj: (state, action) => {
      state.messageObj = action.payload;
    },
    setChatObject: (state, action) => {
      state.setChatInfo = action.payload;
    },
    studentChatReqId: (state, action) => {
      state.setChatRequestId = action.payload;
    },
    getStudentNotifications: (state, action) => {
      state.studentNotifications = action.payload;
    },
  },
});

export const {
  getStudentProfileInfo,
  setTutorList,
  setRemoveFavouriteTutor,
  setTutorDetails,
  setShowHideTutor,
  setFollowUnfollowTutor,
  studentProfileInfo,
  setTutorCourses,
  setAllCourses,
  studentAnyMinutesPlan,
  setSingleCourses,
  setMyCourses,
  enrollCourseStatus,
  allCourses,
  teacherPrioritySlot,
  setHiddenTutorList,
  studentBookSlot,
  videoCallData,
  studentSurveyComment,
  setRegularStudentList,
  getProgressCounter,
  getAllTimeSlot,
  getStudentAttendanceRecord,
  getTwilioToken,
  audioEnableDisable,
  videoEnableDisable,
  studentCallLeave,
  student_upcoming_Slot,
  setMessageObj,
  getStudentCategoryCertificate,
  studentPlanDetalis,
  setChatObject,
  studentChatReqId,
  getStudentNotifications
} = studentInfo.actions;

export default studentInfo.reducer;
