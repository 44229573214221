const DialorIcon = () => (
  <svg width="16.723" height="16.747" viewBox="0 0 16.723 16.747">
    <g id="phone-call_7_" data-name="phone-call (7)" transform="translate(0.199 0.201)">
      <g id="Group_37171" data-name="Group 37171" transform="translate(0 0)">
        <path
          id="Path_28740"
          data-name="Path 28740"
          d="M13.251,36.586a1.608,1.608,0,0,0-1.166-.535,1.662,1.662,0,0,0-1.18.531L9.815,37.67c-.09-.048-.179-.093-.266-.138-.124-.062-.241-.121-.342-.183a11.849,11.849,0,0,1-2.839-2.587,6.99,6.99,0,0,1-.931-1.47c.283-.259.545-.528.8-.787.1-.1.193-.2.29-.293a1.583,1.583,0,0,0,0-2.387l-.942-.942c-.107-.107-.217-.217-.321-.328-.207-.214-.424-.435-.649-.642a1.633,1.633,0,0,0-1.156-.507,1.69,1.69,0,0,0-1.173.507l-.007.007L1.107,29.1a2.524,2.524,0,0,0-.749,1.6A6.048,6.048,0,0,0,.8,33.268a14.855,14.855,0,0,0,2.639,4.4A16.235,16.235,0,0,0,8.846,41.9a8.423,8.423,0,0,0,3.036.9c.072,0,.148.007.217.007a2.6,2.6,0,0,0,1.991-.856c0-.007.01-.01.014-.017a7.842,7.842,0,0,1,.6-.624c.148-.141.3-.29.448-.445a1.721,1.721,0,0,0,.521-1.194,1.658,1.658,0,0,0-.531-1.183Zm1.235,3.633s0,0,0,0c-.135.145-.273.276-.421.421a9.072,9.072,0,0,0-.666.69,1.663,1.663,0,0,1-1.3.549c-.052,0-.107,0-.159,0a7.483,7.483,0,0,1-2.691-.807A15.325,15.325,0,0,1,4.161,37.08a14.006,14.006,0,0,1-2.484-4.137,4.926,4.926,0,0,1-.386-2.16,1.584,1.584,0,0,1,.476-1.025l1.176-1.176a.784.784,0,0,1,.524-.245.738.738,0,0,1,.5.241l.01.01c.21.2.411.4.621.618.107.11.217.221.328.335l.942.942a.658.658,0,0,1,0,1.069c-.1.1-.2.2-.3.3-.29.3-.566.573-.866.842-.007.007-.014.01-.017.017a.7.7,0,0,0-.179.783l.01.031a7.562,7.562,0,0,0,1.114,1.818l0,0A12.663,12.663,0,0,0,8.7,38.132a4.712,4.712,0,0,0,.424.231c.124.062.241.121.342.183.014.007.028.017.041.024a.748.748,0,0,0,.342.086.738.738,0,0,0,.524-.238l1.18-1.18a.78.78,0,0,1,.521-.259.7.7,0,0,1,.5.252l.007.007,1.9,1.9A.682.682,0,0,1,14.486,40.219Z"
          transform="translate(-0.344 -26.461)"
          fill="#fff"
          stroke="#fff"
          strokeWidth="0.4"
        />
        <path
          id="Path_28741"
          data-name="Path 28741"
          d="M245.327,86.849a4.44,4.44,0,0,1,3.616,3.616.463.463,0,0,0,.459.386.616.616,0,0,0,.079-.007.466.466,0,0,0,.383-.538,5.369,5.369,0,0,0-4.375-4.375.468.468,0,0,0-.538.379A.461.461,0,0,0,245.327,86.849Z"
          transform="translate(-236.505 -82.961)"
          fill="#fff"
          stroke="#fff"
          strokeWidth="0.4"
        />
        <path
          id="Path_28742"
          data-name="Path 28742"
          d="M256.52,7.211a8.84,8.84,0,0,0-7.2-7.2.465.465,0,1,0-.152.918A7.9,7.9,0,0,1,255.6,7.363a.463.463,0,0,0,.459.386.616.616,0,0,0,.079-.007A.457.457,0,0,0,256.52,7.211Z"
          transform="translate(-240.205 0)"
          fill="#fff"
          stroke="#fff"
          strokeWidth="0.4"
        />
      </g>
    </g>
  </svg>
);

export default DialorIcon;
