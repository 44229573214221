const SearchIcon = () => (
  <svg width="18.701" height="18.892" viewBox="0 0 18.701 18.892">
    <g id="_x34__4_" transform="translate(0.4 0.4)">
      <g id="Group_1" data-name="Group 1">
        <path
          id="Path_1"
          data-name="Path 1"
          d="M17.737,17.052,13.115,12.47a7.394,7.394,0,0,0,1.954-5,7.532,7.532,0,1,0-7.532,7.466,7.54,7.54,0,0,0,4.74-1.667l4.64,4.6a.583.583,0,0,0,.819,0A.571.571,0,0,0,17.737,17.052Zm-10.2-3.268A6.318,6.318,0,1,1,13.91,7.466,6.345,6.345,0,0,1,7.537,13.784Z"
          transform="translate(-0.006)"
          fill="#005182"
          stroke="#005182"
          strokeWidth="0.8"
        />
      </g>
    </g>
  </svg>
);

export default SearchIcon;
