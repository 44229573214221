const ProfileMessageIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14.521"
    height="14.521"
    viewBox="0 0 14.521 14.521"
  >
    <path
      id="Icon_feather-message-square"
      data-name="Icon feather-message-square"
      d="M18.021,13.514a1.5,1.5,0,0,1-1.5,1.5H7.5l-3,3V6A1.5,1.5,0,0,1,6,4.5H16.519a1.5,1.5,0,0,1,1.5,1.5Z"
      transform="translate(-4 -4)"
      fill="none"
      stroke="#005182"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1"
    />
  </svg>
);
export default ProfileMessageIcon;
