const MessageInputMicrophone = () => (
  <svg width="14.509" height="23.005" viewBox="0 0 14.509 23.005">
    <g id="microphone_1_" data-name="microphone (1)" transform="translate(-14.34 -4)">
      <path
        id="Path_43966"
        data-name="Path 43966"
        d="M27.617,26.181a6.022,6.022,0,1,1-12.045,0V24H14.34v2.181A7.261,7.261,0,0,0,20.978,33.4v4.152H17.951v1.232h7.286V37.556H22.211V33.4a7.261,7.261,0,0,0,6.639-7.224V24H27.617Z"
        transform="translate(0 -11.784)"
        fill="#fff"
      ></path>
      <path
        id="Path_43967"
        data-name="Path 43967"
        d="M25.131,19.188A4.794,4.794,0,0,0,29.92,14.4V8.789a4.789,4.789,0,1,0-9.578,0V14.4A4.794,4.794,0,0,0,25.131,19.188Zm-3.557-10.4a3.557,3.557,0,1,1,7.113,0V14.4a3.557,3.557,0,1,1-7.113,0Z"
        transform="translate(-3.537)"
        fill="#fff"
      ></path>
    </g>
  </svg>
);

export default MessageInputMicrophone;
