import React, { Fragment } from 'react';

const CompletedIcon = () => {
  return (
    <Fragment>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="11.578"
        height="13.217"
        viewBox="0 0 11.578 13.217"
      >
        <g id="noun_complete_3202980" transform="translate(0 0)">
          <path
            id="Path_35887"
            data-name="Path 35887"
            d="M17.751,5.374A.615.615,0,0,0,17,6.348a4.528,4.528,0,0,1,1.767,3.6,4.578,4.578,0,1,1-4.94-4.55l-.571.571a.613.613,0,0,0,0,.863.6.6,0,0,0,.863,0l1.628-1.628a.6.6,0,0,0,0-.863L14.119,2.7a.61.61,0,0,0-.863.863l.6.6a5.779,5.779,0,1,0,3.9,1.211Z"
            transform="translate(-8.4 -2.525)"
            fill="#fff"
          />
          <path
            id="Path_35888"
            data-name="Path 35888"
            d="M32.962,44.267l-.93-.93a.591.591,0,0,0-.836.836l1.348,1.348a.586.586,0,0,0,.836,0l2.588-2.588a.591.591,0,0,0-.836-.836Z"
            transform="translate(-28.025 -35.925)"
            fill="#fff"
          />
        </g>
      </svg>
    </Fragment>
  );
};

export default CompletedIcon;
