const NiHaiCertificateIcon = () => (
  <svg className="fill_svg" width="17.647" height="15.261" viewBox="0 0 17.647 15.261">
    <g id="document_3_" data-name="document (3)" transform="translate(0 -17.081)">
      <path
        id="Path_35961"
        data-name="Path 35961"
        d="M16.425,17.081H1.222A1.224,1.224,0,0,0,0,18.3V28.617A1.224,1.224,0,0,0,1.222,29.84h8.865L9.82,31.472a.706.706,0,0,0,1.064.767L12.8,31.232a.4.4,0,0,1,.254,0l1.913,1.007a.853.853,0,0,0,.4.1.671.671,0,0,0,.4-.125.742.742,0,0,0,.272-.745l-.268-1.632h.666a1.224,1.224,0,0,0,1.222-1.222V18.3A1.224,1.224,0,0,0,16.425,17.081Zm-3.5,10.664a2.13,2.13,0,1,1,2.13-2.13A2.133,2.133,0,0,1,12.923,27.745Zm.615,2.56a1.414,1.414,0,0,0-1.23,0l-1.352.712.43-2.622a3.17,3.17,0,0,0,3.075,0l.168,1.022v.005l.261,1.595ZM16.6,28.617a.177.177,0,0,1-.175.175h-.838L15.4,27.625s0-.009,0-.014a3.178,3.178,0,1,0-4.94,0l0,.014-.191,1.168H1.222a.177.177,0,0,1-.175-.175V18.3a.177.177,0,0,1,.175-.175h15.2a.177.177,0,0,1,.175.175Z"
        fill="#005182"
      />
      <path
        id="Path_35962"
        data-name="Path 35962"
        d="M43.518,60.243H31.643a.524.524,0,0,0,0,1.048H43.518a.524.524,0,1,0,0-1.048Z"
        transform="translate(-28.945 -40.147)"
        fill="#005182"
      />
      <path
        id="Path_35963"
        data-name="Path 35963"
        d="M36.672,100.911H31.643a.524.524,0,0,0,0,1.048h5.029a.524.524,0,1,0,0-1.048Z"
        transform="translate(-28.945 -77.974)"
        fill="#005182"
      />
    </g>
  </svg>
);

export default NiHaiCertificateIcon;
