const SubscriptionIcon = () => (
  <svg className="fill_svg" width="13.474" height="13.473" viewBox="0 0 13.474 13.473">
    <g id="subscribe-rss-button" transform="translate(0 -0.002)">
      <g id="Group_37203" data-name="Group 37203" transform="translate(0 0.002)">
        <path
          id="Path_28792"
          data-name="Path 28792"
          d="M1.744,72.389a1.744,1.744,0,1,0,1.744,1.744A1.746,1.746,0,0,0,1.744,72.389Z"
          transform="translate(0 -62.403)"
          fill="#005182"
        />
        <path
          id="Path_28793"
          data-name="Path 28793"
          d="M.321,36.383a.276.276,0,0,0-.285.276V38.9a.276.276,0,0,0,.263.276,5.635,5.635,0,0,1,5.4,5.4.276.276,0,0,0,.276.263H8.21a.276.276,0,0,0,.276-.285,8.42,8.42,0,0,0-2.475-5.69A8.42,8.42,0,0,0,.321,36.383Z"
          transform="translate(-0.031 -31.364)"
          fill="#005182"
        />
        <path
          id="Path_28794"
          data-name="Path 28794"
          d="M9.556,3.95A13.4,13.4,0,0,0,.317,0a.266.266,0,0,0-.2.079.276.276,0,0,0-.083.2V2.514A.276.276,0,0,0,.3,2.79,10.808,10.808,0,0,1,10.716,13.2a.276.276,0,0,0,.276.269h2.236a.276.276,0,0,0,.276-.282A13.4,13.4,0,0,0,9.556,3.95Z"
          transform="translate(-0.03 -0.002)"
          fill="#005182"
        />
      </g>
    </g>
  </svg>
);

export default SubscriptionIcon;
