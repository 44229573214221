const RadioIcon = () => (
  <svg width="9.64" height="7.33" viewBox="0 0 9.64 7.33">
    <path
      id="Icon_ionic-md-checkmark"
      data-name="Icon ionic-md-checkmark"
      d="M7.57,13.505,5.3,11.235,4.5,12l3.07,3.063L14.14,8.5l-.8-.766Z"
      transform="translate(-4.5 -7.734)"
      fill="#fff"
    />
  </svg>
);

export default RadioIcon;
