const AccountIcon = () => (
  <svg className="fill_svg" width="18.565" height="16.5" viewBox="0 0 18.565 16.5">
    <g id="_3154376" data-name="3154376" transform="translate(0 -28.481)">
      <path
        id="Path_28827"
        data-name="Path 28827"
        d="M17.234,28.481H1.331A1.334,1.334,0,0,0,0,29.815V43.877a1.1,1.1,0,0,0,1.1,1.1H17.466a1.1,1.1,0,0,0,1.1-1.1V29.815a1.334,1.334,0,0,0-1.331-1.334ZM.725,29.815a.608.608,0,0,1,.606-.609h15.9a.608.608,0,0,1,.606.609V31.97H.725ZM17.84,43.877a.377.377,0,0,1-.374.378H1.1a.377.377,0,0,1-.374-.378V32.7H17.84Z"
        fill="#005182"
      />
      <path
        id="Path_28828"
        data-name="Path 28828"
        d="M306.389,76.589H305.9a.363.363,0,1,0,0,.725h.487a.363.363,0,0,0,0-.725Z"
        transform="translate(-294.46 -46.364)"
        fill="#005182"
      />
      <path
        id="Path_28829"
        data-name="Path 28829"
        d="M368.521,76.589h-.487a.363.363,0,1,0,0,.725h.487a.363.363,0,1,0,0-.725Z"
        transform="translate(-354.339 -46.364)"
        fill="#005182"
      />
      <path
        id="Path_28830"
        data-name="Path 28830"
        d="M430.653,76.589h-.487a.363.363,0,1,0,0,.725h.487a.363.363,0,1,0,0-.725Z"
        transform="translate(-414.218 -46.364)"
        fill="#005182"
      />
      <path
        id="Path_28831"
        data-name="Path 28831"
        d="M65.145,76.589H61.63a.363.363,0,1,0,0,.725h3.515a.363.363,0,1,0,0-.725Z"
        transform="translate(-59.045 -46.364)"
        fill="#005182"
      />
      <path
        id="Path_28832"
        data-name="Path 28832"
        d="M122.63,176.674a5.018,5.018,0,1,0-5.018-5.018A5.024,5.024,0,0,0,122.63,176.674Zm0-9.311a4.293,4.293,0,1,1-4.293,4.293A4.3,4.3,0,0,1,122.63,167.363Z"
        transform="translate(-113.347 -133.147)"
        fill="#005182"
      />
      <path
        id="Path_28833"
        data-name="Path 28833"
        d="M192.766,226.32h3.887a.363.363,0,0,0,.363-.363v-1.243a1.637,1.637,0,0,0-.856-1.432,1.868,1.868,0,1,0-2.9,0,1.635,1.635,0,0,0-.856,1.432v1.243A.363.363,0,0,0,192.766,226.32Zm1.943-5.357a1.143,1.143,0,1,1-1.143,1.143A1.144,1.144,0,0,1,194.709,220.963Zm-1.581,3.752a.906.906,0,0,1,.826-.9,1.868,1.868,0,0,0,1.509,0,.907.907,0,0,1,.826.9v.88h-3.162v-.88Z"
        transform="translate(-185.426 -184.804)"
        fill="#005182"
      />
    </g>
  </svg>
);

export default AccountIcon;
