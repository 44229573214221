const StudentIdIcon = () => (
  <svg width="13.46" height="17.539" viewBox="0 0 13.46 17.539">
    <path
      id="Path_28057"
      data-name="Path 28057"
      d="M17.816,959.362a.823.823,0,0,0-.816.816v15.907a.823.823,0,0,0,.816.816H29.644a.823.823,0,0,0,.816-.816V960.178a.823.823,0,0,0-.816-.816Zm0,.816H29.644v15.907H17.816Zm4.079,1.631a.408.408,0,1,0,0,.816h3.671a.408.408,0,1,0,0-.816Zm1.835,3.263a2.243,2.243,0,1,0,2.243,2.243A2.25,2.25,0,0,0,23.73,965.073Zm0,.816a1.428,1.428,0,1,1-1.428,1.428A1.421,1.421,0,0,1,23.73,965.888Zm0,4.487a5.084,5.084,0,0,0-3.136.994,3.384,3.384,0,0,0-1.351,2.677.424.424,0,0,0,.408.408h8.158a.424.424,0,0,0,.408-.408,3.384,3.384,0,0,0-1.351-2.677A5.084,5.084,0,0,0,23.73,970.375Zm0,.816a4.291,4.291,0,0,1,2.632.822,2.561,2.561,0,0,1,.994,1.625H20.1a2.56,2.56,0,0,1,.994-1.625A4.291,4.291,0,0,1,23.73,971.191Z"
      transform="translate(-17 -959.362)"
      opacity="0.556"
    />
  </svg>
);

export default StudentIdIcon;
