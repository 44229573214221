const ProfileIcon = () => (
  <svg className="fill_svg" width="18.99" height="18.99" viewBox="0 0 18.99 18.99">
    <path
      id="user_23_"
      data-name="user (23)"
      d="M16.209,2.781A9.5,9.5,0,0,0,2.781,16.209,9.5,9.5,0,0,0,16.209,2.781ZM4.76,16.408a4.808,4.808,0,0,1,9.47,0,8.364,8.364,0,0,1-9.47,0ZM6.476,8.3A3.019,3.019,0,1,1,9.5,11.322,3.022,3.022,0,0,1,6.476,8.3Zm8.718,7.333a5.93,5.93,0,0,0-3.447-3.87,4.132,4.132,0,1,0-4.5,0A5.929,5.929,0,0,0,3.8,15.636a8.382,8.382,0,1,1,11.4,0Zm0,0"
      fill="#005182"
    />
  </svg>
);

export default ProfileIcon;
