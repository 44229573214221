import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  companyInfo: {},
  bankInfo: '',
  companyList: '',
  companyListFilter: '',
};

const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    setCompanyInfo: (state, action) => {
      state.companyInfo = action.payload;
    },
    setCoBankInfo: (state, action) => {
      state.bankInfo = action.payload;
    },
    setCompanyList: (state, action) => {
      state.companyList = action.payload;
    },
    setCompanyListFilter: (state, action) => {
      state.companyListFilter = action.payload;
    },
  },
});

export const { setCompanyInfo, setCoBankInfo, setCompanyList, setCompanyListFilter } =
  companySlice.actions;

export default companySlice.reducer;
