const GoogleIcon = () => (
  <svg id="google" width="15.992" height="15.414" viewBox="0 0 15.992 15.414">
    <path
      id="Path"
      d="M3.348,3.414a4.334,4.334,0,0,1,.245-1.431L.84,0a7.359,7.359,0,0,0,0,6.825L3.59,4.838a4.317,4.317,0,0,1-.242-1.424"
      transform="translate(0 4.293)"
      fill="#fff"
    ></path>
    <path
      id="Path-2"
      data-name="Path"
      d="M7.344,3.153a4.912,4.912,0,0,1,3.012,1.015l2.381-2.241A8.442,8.442,0,0,0,7.344,0,8.218,8.218,0,0,0,0,4.293L2.754,6.276a4.8,4.8,0,0,1,4.59-3.122"
      transform="translate(0.838 0)"
      fill="#fff"
    ></path>
    <path
      id="Path-3"
      data-name="Path"
      d="M7.344,3.122A4.8,4.8,0,0,1,2.754,0L0,1.982A8.218,8.218,0,0,0,7.344,6.276a8.062,8.062,0,0,0,5.33-1.921L10.061,2.448a5.285,5.285,0,0,1-2.717.674"
      transform="translate(0.838 9.138)"
      fill="#fff"
    ></path>
    <path
      id="Path-4"
      data-name="Path"
      d="M7.809,1.4A6.042,6.042,0,0,0,7.623,0H0V2.978H4.387a3.469,3.469,0,0,1-1.671,2.3L5.33,7.187A7.451,7.451,0,0,0,7.809,1.4"
      transform="translate(8.182 6.306)"
      fill="#fff"
    ></path>
  </svg>
);

export default GoogleIcon;
