const ProfileCalendarIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="13.522" height="14.61" viewBox="0 0 13.522 14.61">
    <g id="Group_37204" data-name="Group 37204" opacity="0.818">
      <path
        id="Path_25669"
        data-name="Path 25669"
        d="M.465,1.887H2.409V.789a.571.571,0,0,1,1.141,0v1.1H6.212V.789a.549.549,0,0,1,1.1,0v1.1H9.972V.789a.571.571,0,0,1,1.141,0v1.1H13.1a.463.463,0,0,1,.423.465V14.395a.463.463,0,0,1-.423.465H.465A.469.469,0,0,1,0,14.395V2.352A.469.469,0,0,1,.465,1.887ZM.718,5.141H12.8v9H.718Z"
        transform="translate(0 -0.25)"
        fill="#005182"
        fillRule="evenodd"
      />
      <g id="Group_13121" data-name="Group 13121" transform="translate(2.493 5.821)">
        <path
          id="Path_25671"
          data-name="Path 25671"
          d="M117,138h1.183v1.141H117Z"
          transform="translate(-114.549 -138)"
          fill="#005182"
          fillRule="evenodd"
        />
        <path
          id="Path_25672"
          data-name="Path 25672"
          d="M176,138h1.141v1.141H176Z"
          transform="translate(-171.056 -138)"
          fill="#005182"
          fillRule="evenodd"
        />
        <path
          id="Path_25673"
          data-name="Path 25673"
          d="M234,138h1.141v1.141H234Z"
          transform="translate(-226.605 -138)"
          fill="#005182"
          fillRule="evenodd"
        />
        <path
          id="Path_25674"
          data-name="Path 25674"
          d="M59,185h1.141v1.141H59Z"
          transform="translate(-59 -183.014)"
          fill="#005182"
          fillRule="evenodd"
        />
        <path
          id="Path_25675"
          data-name="Path 25675"
          d="M59,232h1.141v1.141H59Z"
          transform="translate(-59 -228.028)"
          fill="#005182"
          fillRule="evenodd"
        />
        <path
          id="Path_25676"
          data-name="Path 25676"
          d="M59,279h1.141v1.141H59Z"
          transform="translate(-59 -273.042)"
          fill="#005182"
          fillRule="evenodd"
        />
        <path
          id="Path_25677"
          data-name="Path 25677"
          d="M117,185h1.183v1.141H117Z"
          transform="translate(-114.549 -183.014)"
          fill="#005182"
          fillRule="evenodd"
        />
        <path
          id="Path_25678"
          data-name="Path 25678"
          d="M117,232h1.183v1.141H117Z"
          transform="translate(-114.549 -228.028)"
          fill="#005182"
          fillRule="evenodd"
        />
        <path
          id="Path_25679"
          data-name="Path 25679"
          d="M117,279h1.183v1.141H117Z"
          transform="translate(-114.549 -273.042)"
          fill="#005182"
          fillRule="evenodd"
        />
        <path
          id="Path_25680"
          data-name="Path 25680"
          d="M176,185h1.141v1.141H176Z"
          transform="translate(-171.056 -183.014)"
          fill="#005182"
          fillRule="evenodd"
        />
        <path
          id="Path_25681"
          data-name="Path 25681"
          d="M176,232h1.141v1.141H176Z"
          transform="translate(-171.056 -228.028)"
          fill="#005182"
          fillRule="evenodd"
        />
        <path
          id="Path_25682"
          data-name="Path 25682"
          d="M176,279h1.141v1.141H176Z"
          transform="translate(-171.056 -273.042)"
          fill="#005182"
          fillRule="evenodd"
        />
        <path
          id="Path_25683"
          data-name="Path 25683"
          d="M234,185h1.141v1.141H234Z"
          transform="translate(-226.605 -183.014)"
          fill="#005182"
          fillRule="evenodd"
        />
        <path
          id="Path_25684"
          data-name="Path 25684"
          d="M234,232h1.141v1.141H234Z"
          transform="translate(-226.605 -228.028)"
          fill="#005182"
          fillRule="evenodd"
        />
        <path
          id="Path_25685"
          data-name="Path 25685"
          d="M234,279h1.141v1.141H234Z"
          transform="translate(-226.605 -273.042)"
          fill="#005182"
          fillRule="evenodd"
        />
        <path
          id="Path_25670"
          data-name="Path 25670"
          d="M59,138h1.141v1.141H59Z"
          transform="translate(-59 -138)"
          fill="#005182"
          fillRule="evenodd"
        />
      </g>
    </g>
  </svg>
);

export default ProfileCalendarIcon;
