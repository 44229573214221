const SearchTutorIcon = () => (
  <svg width="16.108" height="16.108" viewBox="0 0 16.108 16.108">
    <path
      id="search"
      d="M12.617,12.617A6.273,6.273,0,0,0,3.745,3.745a6.273,6.273,0,0,0,8.872,8.872ZM4.875,11.488a4.676,4.676,0,1,1,6.614,0A4.646,4.646,0,0,1,4.875,11.488Zm12.908,6.295a.8.8,0,0,0,0-1.129L14.433,13.3A.8.8,0,0,0,13.3,14.433l3.349,3.349A.8.8,0,0,0,17.782,17.782Z"
      transform="translate(-1.908 -1.908)"
      fill="#0a9ef8"
    />
  </svg>
);

export default SearchTutorIcon;
