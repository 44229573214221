const EmailPreferencesIcon = () => (
  <svg className="fill_svg" width="15.897" height="15.897" viewBox="0 0 15.897 15.897">
    <g id="_1034146" data-name="1034146" transform="translate(-0.001 -0.004)">
      <g id="Group_37235" data-name="Group 37235" transform="translate(0.001 0.004)">
        <g id="Group_37234" data-name="Group 37234" transform="translate(0 0)">
          <path
            id="Path_28836"
            data-name="Path 28836"
            d="M15.9,6.082a.251.251,0,0,0-.011-.054.265.265,0,0,0-.015-.046.238.238,0,0,0-.026-.041.25.25,0,0,0-.035-.041s-.006-.009-.011-.012L13.249,3.909V1.858a.8.8,0,0,0-.795-.795H9.582L8.433.171a.786.786,0,0,0-.966,0l-1.15.893H3.445a.8.8,0,0,0-.795.795V3.909L.1,5.888S.1,5.9.092,5.9a.249.249,0,0,0-.035.041.236.236,0,0,0-.026.041.261.261,0,0,0-.015.046A.251.251,0,0,0,0,6.081c0,.006,0,.01,0,.016v9.01a.786.786,0,0,0,.156.468s0,.005,0,.007l.008.007A.791.791,0,0,0,.8,15.9H15.1a.791.791,0,0,0,.629-.314s.005,0,.007-.006,0-.005,0-.007a.786.786,0,0,0,.156-.468V6.1C15.9,6.092,15.9,6.087,15.9,6.082ZM7.79.589a.254.254,0,0,1,.316,0l.611.475H7.181ZM.862,15.372,7.79,9.99a.254.254,0,0,1,.316,0l6.93,5.382Zm14.507-.413L8.433,9.572a.786.786,0,0,0-.966,0L.53,14.959V6.5L4.872,9.873A.265.265,0,0,0,5.2,9.454L.786,6.029,2.65,4.58V6.628a.265.265,0,0,0,.53,0V1.858a.265.265,0,0,1,.265-.265h9.009a.265.265,0,0,1,.265.265V6.628a.265.265,0,1,0,.53,0V4.58l1.864,1.449L10.694,9.46a.265.265,0,1,0,.325.419L15.368,6.5v8.457Z"
            transform="translate(-0.001 -0.004)"
            fill="#005182"
          />
          <path
            id="Path_28837"
            data-name="Path 28837"
            d="M159.933,72.477v-1.06a3.18,3.18,0,1,0-3.18,3.18.265.265,0,0,0,0-.53,2.65,2.65,0,1,1,2.65-2.65v1.06a.53.53,0,0,1-1.06,0v-1.06a.265.265,0,1,0-.53,0,1.06,1.06,0,1,1-1.06-1.06.265.265,0,0,0,0-.53,1.59,1.59,0,1,0,1.071,2.761,1.057,1.057,0,0,0,2.108-.112Z"
            transform="translate(-148.805 -66.118)"
            fill="#005182"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default EmailPreferencesIcon;
