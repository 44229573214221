const FacebookIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="6.454" height="14.906" viewBox="0 0 7.454 14.906">
    <path
      id="facebook"
      d="M12.28,2.475h1.361V.1A17.572,17.572,0,0,0,11.658,0C9.7,0,8.352,1.234,8.352,3.5V5.59H6.187v2.65H8.352v6.667h2.655V8.24h2.078l.33-2.65H11.006V3.765C11.007,3,11.213,2.475,12.28,2.475Z"
      transform="translate(-6.187)"
      fill="#fff"
    ></path>
  </svg>
);

export default FacebookIcon;
