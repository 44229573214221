const BookIcon = () => (
  <svg width="16.511" height="14.96" viewBox="0 0 16.511 14.96">
    <g
      id="Icon_feather-book-open"
      data-name="Icon feather-book-open"
      transform="translate(-2.5 -4)"
    >
      <path
        id="Path_17"
        data-name="Path 17"
        d="M3,4.5H7.653a3.1,3.1,0,0,1,3.1,3.1V18.46a2.327,2.327,0,0,0-2.327-2.327H3Z"
        fill="none"
        stroke="#005182"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        id="Path_18"
        data-name="Path 18"
        d="M25.756,4.5H21.1A3.1,3.1,0,0,0,18,7.6V18.46a2.327,2.327,0,0,1,2.327-2.327h5.429Z"
        transform="translate(-7.244)"
        fill="none"
        stroke="#005182"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
    </g>
  </svg>
);

export default BookIcon;
