const CalendarAboutIcon = () => (
  <svg
    id="Group_41509"
    data-name="Group 41509"
    xmlns="http://www.w3.org/2000/svg"
    width="12.1"
    height="13.444"
    viewBox="0 0 12.1 13.444"
  >
    <path
      id="Icon_material-date-range"
      data-name="Icon material-date-range"
      d="M15.255,4.344h-.672V3H13.239V4.344H7.861V3H6.517V4.344H5.844A1.338,1.338,0,0,0,4.507,5.689L4.5,15.1a1.344,1.344,0,0,0,1.344,1.344h9.411A1.348,1.348,0,0,0,16.6,15.1V5.689A1.348,1.348,0,0,0,15.255,4.344Zm0,10.755H5.844V7.705h9.411Z"
      transform="translate(-4.5 -3)"
      fill="#009ff4"
    />
    <path
      id="Icon_ionic-ios-checkmark"
      data-name="Icon ionic-ios-checkmark"
      d="M15.716,12.682l-.434-.446a.093.093,0,0,0-.069-.03h0a.09.09,0,0,0-.069.03l-3.008,3.03-1.095-1.095a.1.1,0,0,0-.138,0l-.439.439a.1.1,0,0,0,0,.141l1.381,1.381a.437.437,0,0,0,.288.141.458.458,0,0,0,.286-.136h0l3.3-3.314A.105.105,0,0,0,15.716,12.682Z"
      transform="translate(-7.039 -5.668)"
      fill="#009ff4"
    />
  </svg>
);

export default CalendarAboutIcon;
