const DropDownArrow = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="9.478"
        height="5.339"
        viewBox="0 0 9.478 5.339"
      >
        <path
          id="Icon_feather-chevron-down"
          data-name="Icon feather-chevron-down"
          d="M9,13.5l3.891,3.891L16.781,13.5"
          transform="translate(-8.151 -12.651)"
          fill="none"
          stroke="#67dd9c"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.2"
        />
      </svg>
    </>
  );
};

export default DropDownArrow;
