import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  allPlansRes: '',
};

const planSlice = createSlice({
  name: 'plans',
  initialState,
  reducers: {
    allPlansResponse: (state, action) => {
      state.allPlansRes = action.payload;
    },
  },
});

export const { allPlansResponse } = planSlice.actions;

export default planSlice.reducer;
