import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import { createRoot } from 'react-dom/client';
import './index.css';
import './i18next.js';
import 'react-big-calendar/lib/css/react-big-calendar.css';

ReactDOM.render(
    <App />,
  document.querySelector('#root'),
);

// const container = document.querySelector('#root');
// const root = createRoot(container);
// root.render(
//   // <StrictMode>
//   <App />,
//   // </StrictMode>,
// );

reportWebVitals();
