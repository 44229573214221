const LogoutIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14.646"
    height="14.597"
    viewBox="0 0 14.646 14.597"
  >
    <g id="logout_12_" data-name="logout (12)" transform="translate(0 -0.85)">
      <g id="Group_37200" data-name="Group 37200" transform="translate(0 0.85)">
        <g id="Group_37199" data-name="Group 37199" transform="translate(0 0)">
          <path
            id="Path_28790"
            data-name="Path 28790"
            d="M7.3,14.23H1.825a.608.608,0,0,1-.608-.608V2.675a.608.608,0,0,1,.608-.608H7.3A.608.608,0,0,0,7.3.85H1.825A1.827,1.827,0,0,0,0,2.675V13.622a1.827,1.827,0,0,0,1.825,1.825H7.3a.608.608,0,0,0,0-1.216Z"
            transform="translate(0 -0.85)"
            fill="#f66962"
          />
        </g>
      </g>
      <g id="Group_37202" data-name="Group 37202" transform="translate(4.866 3.891)">
        <g id="Group_37201" data-name="Group 37201" transform="translate(0 0)">
          <path
            id="Path_28791"
            data-name="Path 28791"
            d="M179.7,110.989,176,107.34a.608.608,0,1,0-.854.866l2.643,2.608h-7.081a.608.608,0,0,0,0,1.216h7.081l-2.643,2.608a.608.608,0,1,0,.854.866l3.7-3.649a.608.608,0,0,0,0-.866Z"
            transform="translate(-170.1 -107.165)"
            fill="#f66962"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default LogoutIcon;
