const AccountSettingIcon = () => (
  <svg
    id="profile_1_"
    data-name="profile (1)"
    width="16.851"
    height="17.461"
    viewBox="0 0 16.851 17.461"
  >
    <g id="Group_37194" data-name="Group 37194" transform="translate(2.91)">
      <path
        id="Path_28786"
        data-name="Path 28786"
        d="M7.638,7.276a3.638,3.638,0,1,1,3.638-3.638A3.642,3.642,0,0,1,7.638,7.276Zm0-6.548a2.91,2.91,0,1,0,2.91,2.91A2.913,2.913,0,0,0,7.638.728Z"
        transform="translate(-4)"
        fill="#005182"
      />
    </g>
    <g id="Group_37195" data-name="Group 37195" transform="translate(0 8.731)">
      <path
        id="Path_28787"
        data-name="Path 28787"
        d="M6.184,18.548H.364A.364.364,0,0,1,0,18.184v-2.91A3.278,3.278,0,0,1,3.274,12H7.639a.364.364,0,0,1,0,.728H3.274A2.549,2.549,0,0,0,.728,15.274V17.82H6.184a.364.364,0,1,1,0,.728Z"
        transform="translate(0 -12)"
        fill="#005182"
      />
    </g>
    <g id="Group_37198" data-name="Group 37198" transform="translate(7.885 8.004)">
      <g id="Group_37196" data-name="Group 37196" transform="translate(2.664 2.909)">
        <path
          id="Path_28788"
          data-name="Path 28788"
          d="M16.319,18.638a1.819,1.819,0,1,1,1.819-1.819A1.821,1.821,0,0,1,16.319,18.638Zm0-2.91a1.091,1.091,0,1,0,1.091,1.091A1.093,1.093,0,0,0,16.319,15.728Z"
          transform="translate(-14.5 -15)"
          fill="#005182"
        />
      </g>
      <g id="Group_37197" data-name="Group 37197">
        <path
          id="Path_28789"
          data-name="Path 28789"
          d="M15.9,20.459h-1.16a.725.725,0,0,1-.724-.656l-.092-.925a3.417,3.417,0,0,1-.629-.366l-.85.383a.724.724,0,0,1-.929-.3l-.58-1a.726.726,0,0,1,.205-.955l.749-.538a2.763,2.763,0,0,1,0-.738l-.749-.538a.726.726,0,0,1-.205-.955l.58-1a.725.725,0,0,1,.929-.3l.85.383a3.417,3.417,0,0,1,.629-.366l.092-.925A.725.725,0,0,1,14.741,11H15.9a.725.725,0,0,1,.724.656l.092.925a3.41,3.41,0,0,1,.629.366l.85-.383a.726.726,0,0,1,.929.3l.58,1a.726.726,0,0,1-.205.955l-.749.538a2.762,2.762,0,0,1,0,.738l.749.538a.726.726,0,0,1,.205.955l-.579,1a.725.725,0,0,1-.929.3l-.85-.383a3.416,3.416,0,0,1-.629.366l-.093.925a.725.725,0,0,1-.724.655Zm-2.555-2.732a.364.364,0,0,1,.234.085,2.709,2.709,0,0,0,.81.471.364.364,0,0,1,.237.306l.115,1.143H15.9l.114-1.143a.364.364,0,0,1,.237-.306,2.725,2.725,0,0,0,.81-.471.363.363,0,0,1,.383-.052l1.051.474.58-1-.93-.668A.366.366,0,0,1,18,16.2a2.3,2.3,0,0,0,0-.943.365.365,0,0,1,.146-.359l.93-.668-.58-1-1.051.474a.364.364,0,0,1-.383-.052,2.683,2.683,0,0,0-.81-.471.364.364,0,0,1-.237-.306L15.9,11.728h-1.16l-.114,1.143a.364.364,0,0,1-.237.306,2.725,2.725,0,0,0-.81.471.362.362,0,0,1-.383.052l-1.051-.474-.58,1,.93.668a.366.366,0,0,1,.146.359,2.3,2.3,0,0,0,0,.943.365.365,0,0,1-.146.359l-.93.668.58,1,1.051-.474a.38.38,0,0,1,.149-.031Z"
          transform="translate(-10.838 -11.001)"
          fill="#005182"
        />
      </g>
    </g>
  </svg>
);

export default AccountSettingIcon;
