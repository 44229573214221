const RecordIcon = () => (
  <svg width="18.6" height="14.471" viewBox="0 0 18.6 14.471">
    <g id="Group_37609" data-name="Group 37609" transform="translate(0 0)">
      <path
        id="Path_36031"
        data-name="Path 36031"
        d="M26.422,6.26H23.946L22.83,8.436h2.332Z"
        transform="translate(-15.301 -6.26)"
        fill="#fff"
      />
      <path
        id="Path_36032"
        data-name="Path 36032"
        d="M39.222,6.26H36.746L35.63,8.436h2.332Z"
        transform="translate(-23.88 -6.26)"
        fill="#fff"
      />
      <path
        id="Path_36033"
        data-name="Path 36033"
        d="M51.009,6.26H49.547L48.43,8.436h2.579Z"
        transform="translate(-32.458 -6.26)"
        fill="#fff"
      />
      <path
        id="Path_36034"
        data-name="Path 36034"
        d="M6.9,6.358H0V8.5H5.64Z"
        transform="translate(0 -6.326)"
        fill="#fff"
      />
      <path
        id="Path_36035"
        data-name="Path 36035"
        d="M.15,26.659a1.374,1.374,0,0,0,1.374,1.374h15.8A1.374,1.374,0,0,0,18.7,26.659v-9.5H.15Zm6.945-5.382a.781.781,0,0,1,1.229-.76l2.923,1.462c.679.339.679.889,0,1.229L8.324,24.67a.781.781,0,0,1-1.229-.76Z"
        transform="translate(-0.101 -13.561)"
        fill="#fff"
      />
    </g>
  </svg>
);

export default RecordIcon;
