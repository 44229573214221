const VideoIcon = () => (
  <svg width="20.05" height="13.123" viewBox="0 0 20.05 13.123">
    <g id="Icon_feather-video" data-name="Icon feather-video" transform="translate(-1 -7)">
      <path
        id="Path_19"
        data-name="Path 19"
        d="M30.061,10.5,24,14.83l6.061,4.33Z"
        transform="translate(-9.511 -1.268)"
        fill="none"
        stroke="#005182"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        id="Path_20"
        data-name="Path 20"
        d="M3.232,7.5h9.525a1.732,1.732,0,0,1,1.732,1.732v8.659a1.732,1.732,0,0,1-1.732,1.732H3.232A1.732,1.732,0,0,1,1.5,17.891V9.232A1.732,1.732,0,0,1,3.232,7.5Z"
        transform="translate(0 0)"
        fill="none"
        stroke="#005182"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
    </g>
  </svg>
);

export default VideoIcon;
