const SwitchRoleIcon = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15.609"
        height="14.426"
        viewBox="0 0 15.609 14.426"
      >
        <g id="swap_2_" data-name="swap (2)" transform="translate(0.544 -18.82)">
          <path
            id="Path_28799"
            data-name="Path 28799"
            d="M2.629,24.9a.454.454,0,0,0,.642,0l.587-.587a.454.454,0,0,0,0-.642l-.587-.587h8.074a1.363,1.363,0,0,1,1.361,1.361.682.682,0,0,0,.681.681h.454a.682.682,0,0,0,.681-.681,3.18,3.18,0,0,0-3.177-3.177H3.27l.587-.587a.454.454,0,0,0,0-.642l-.587-.587a.454.454,0,0,0-.642,0L.066,22.015a.227.227,0,0,0,0,.321Z"
            transform="translate(0 0)"
            fill="#005182"
            stroke="#fff"
            strokeWidth={1}
          />
          <path
            id="Path_28800"
            data-name="Path 28800"
            d="M11.893,288.227a.454.454,0,0,0-.642,0l-.587.587a.453.453,0,0,0,0,.642l.587.587H3.177a1.363,1.363,0,0,1-1.361-1.361A.682.682,0,0,0,1.134,288H.681a.682.682,0,0,0-.681.681,3.18,3.18,0,0,0,3.177,3.177h8.074l-.587.587a.453.453,0,0,0,0,.641l.587.587a.454.454,0,0,0,.642,0l2.563-2.562a.227.227,0,0,0,0-.321Z"
            transform="translate(0 -261.06)"
            fill="#005182"
            stroke="#fff"
            strokeWidth={1}
          />
        </g>
      </svg>
    </>
  );
};

export default SwitchRoleIcon;
