const RatingStarIcon = () => (
  <svg
    id="icons"
    xmlns="http://www.w3.org/2000/svg"
    width="7.845"
    height="7.839"
    viewBox="0 0 7.845 7.839"
  >
    <path
      id="favorite"
      d="M6.236,2.227l.9,1.956a.414.414,0,0,0,.274.235l2.034.313a.354.354,0,0,1,.235.626L8.192,6.881a.546.546,0,0,0-.117.352l.352,2.112a.393.393,0,0,1-.548.43L6.041,8.759a.293.293,0,0,0-.352,0L3.85,9.776a.377.377,0,0,1-.548-.43l.352-2.112a.394.394,0,0,0-.117-.352L2.051,5.356a.406.406,0,0,1,.235-.665L4.32,4.378a.336.336,0,0,0,.274-.235l.9-1.956A.424.424,0,0,1,6.236,2.227Z"
      transform="translate(-1.956 -1.987)"
      fill="#ffc453"
    />
  </svg>
);

export default RatingStarIcon;
