const GraduationCapIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17.628"
    height="10.577"
    viewBox="0 0 17.628 10.577"
  >
    <path
      id="Icon_awesome-graduation-cap"
      data-name="Icon awesome-graduation-cap"
      d="M.487,6.957,8.17,4.6a2.2,2.2,0,0,1,1.289,0l7.683,2.361a.648.648,0,0,1,0,1.256L15.8,8.624A2.171,2.171,0,0,1,16.3,9.916a.85.85,0,0,1,.07,1.46l.7,3.164a.441.441,0,0,1-.43.536H15.093a.441.441,0,0,1-.43-.536l.7-3.164a.848.848,0,0,1,.05-1.447,1.3,1.3,0,0,0-.57-1.011L9.459,10.573a2.2,2.2,0,0,1-1.289,0L.487,8.213a.649.649,0,0,1,0-1.256Zm7.425,4.459a3.083,3.083,0,0,0,1.807,0l3.994-1.227.391,3.125c0,.974-2.368,1.763-5.289,1.763s-5.289-.789-5.289-1.763l.391-3.125,3.995,1.227Z"
      transform="translate(0 -4.5)"
      fill="#949494"
    />
  </svg>
);

export default GraduationCapIcon;
