import { lazy, Suspense } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './lib/niHaoCss';
import store from './store';
import Footer from './components/common/layout/footer';
const App = lazy(() => import('../src/container/app'));

const MainApp = () => {
  const toastOptions = {
    position: 'top-right',
    autoClose: 2000,
    hideProgressBar: false,
    newestOnTop: false,
    closeOnClick: true,
    rtl: false,
    pauseOnFocusLoss: true,
    draggable: true,
    pauseOnHover: true,
  };

  return (
    // <Suspense fallback={<Loader />}>
    <Suspense fallback={<div className="contentCenter">Loading...</div>}>
      <Provider store={store}>
        <Router>
          <ToastContainer {...toastOptions} />
          <App />
          {/* <Footer/> */}
        </Router>
      </Provider>
    </Suspense>
  );
};

export default MainApp;
