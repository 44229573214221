const WeChatIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="523.539"
    height="445.157"
    viewBox="0 0 523.539 445.157"
  >
    <g
      id="_7676381_messaging_we_chat_icon"
      data-name="7676381_messaging_we_chat_icon"
      transform="translate(-0.006 -1.803)"
    >
      <path
        id="Path_43964"
        data-name="Path 43964"
        d="M192.422,241.92a109.273,109.273,0,0,1,34.967-60.974,152.982,152.982,0,0,1,116.048-37.809,10.49,10.49,0,0,0,8.96-3.715,10.927,10.927,0,0,0,2.4-9.4,156.042,156.042,0,0,0-78.24-102.5A204.123,204.123,0,0,0,109.155,13.538a171.559,171.559,0,0,0-88.73,69.935,132.658,132.658,0,0,0-16.61,102.28,151.234,151.234,0,0,0,60.1,86.107Q56.7,292.513,50.148,313.166A10.927,10.927,0,0,0,65.665,326.06l35.842-19.014,16.172-8.742a220.95,220.95,0,0,0,69.279,9.179,10.927,10.927,0,0,0,9.616-14.424,109.274,109.274,0,0,1-4.152-51.14Zm21.855-140.744a21.671,21.671,0,1,1,21.2,25.351,20.325,20.325,0,0,1-13.768-5.245,20.543,20.543,0,0,1-7.431-20.106Zm-72.557,9.179A22.836,22.836,0,0,1,120.3,126.527a20.543,20.543,0,0,1-13.55-5.027,22.053,22.053,0,1,1,34.967-11.146Z"
        transform="translate(0 0)"
        fill="#fff"
      />
      <path
        id="Path_43965"
        data-name="Path 43965"
        d="M303.272,93.643A137.247,137.247,0,0,0,239.02,28.079a173.526,173.526,0,0,0-155.824,0A131.128,131.128,0,0,0,12.824,115.5,112.988,112.988,0,0,0,27.029,199.42a151.015,151.015,0,0,0,102.28,67.094,182.486,182.486,0,0,0,84.359-4.589,232.545,232.545,0,0,1,21.855,11.8c6.338,3.5,12.676,7.212,19.232,10.272a11.145,11.145,0,0,0,4.808,0,10.927,10.927,0,0,0,10.49-13.987q-5.027-16.609-10.49-33a135.5,135.5,0,0,0,39.557-46.332,112.989,112.989,0,0,0,4.152-97.035ZM140.237,106.974a22.837,22.837,0,0,1-21.418,16.172,20.543,20.543,0,0,1-13.55-5.027,22.053,22.053,0,1,1,34.967-11.146Zm92.008,0a22.837,22.837,0,0,1-21.418,16.172,20.543,20.543,0,0,1-13.55-5.027,22.053,22.053,0,1,1,34.967-11.146Z"
        transform="translate(211.287 162.701)"
        fill="#fff"
      />
    </g>
  </svg>
);

export default WeChatIcon;
