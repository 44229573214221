import React, { Fragment } from 'react';

const GreenDownArrow = () => {
  return (
    <Fragment>
      <svg width="10.589" height="10.589" viewBox="0 0 10.589 10.589">
        <g
          id="Icon_ionic-ios-arrow-dropdown"
          data-name="Icon ionic-ios-arrow-dropdown"
          transform="translate(-3.375 -3.375)"
        >
          <path
            id="Path_28845"
            data-name="Path 28845"
            d="M10.126,14.559a.493.493,0,0,0,0,.695l2.436,2.428a.491.491,0,0,0,.677.015l2.4-2.393a.49.49,0,1,0-.692-.695l-2.062,2.031-2.064-2.085A.491.491,0,0,0,10.126,14.559Z"
            transform="translate(-4.216 -7.042)"
            fill="#0a9ef8"
          ></path>
          <path
            id="Path_28846"
            data-name="Path 28846"
            d="M3.375,8.669A5.294,5.294,0,1,0,8.669,3.375,5.294,5.294,0,0,0,3.375,8.669ZM11.836,5.5A4.474,4.474,0,1,1,8.669,4.19,4.441,4.441,0,0,1,11.836,5.5Z"
            fill="#0a9ef8"
          ></path>
        </g>
      </svg>
    </Fragment>
  );
};

export default GreenDownArrow;
