import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  locationTimeZoneLanguage: {},
  countries: {},
  state: {},
  city: {},
  language: {},
  timezone: {},
  all_settings_data:{},
  all_FAQ_Questions:[]
};

const locationInfo = createSlice({
  name: 'locationInfo',
  initialState,
  reducers: {
    getLocationInfo: (state, action) => {
      state.locationTimeZoneLanguage = action.payload;
      state.language = action.payload.allLanguages;
      state.timezone = action.payload.allTimeZonessss;
      state.countries = action.payload.countrys;
    },
    all_settings_data: (state, action) => {
      state.all_settings_data = action.payload;
  },
  all_FAQ_Questions: (state, action) => {
    state.all_FAQ_Questions = action.payload;
},
}
});

export const { getLocationInfo, all_settings_data,all_FAQ_Questions } = locationInfo.actions;

export default locationInfo.reducer;
