const StarIcon = () => (
  <svg width="10.11" height="10.101" viewBox="0 0 10.11 10.101">
    <path
      id="favorite"
      d="M7.472,2.3l1.159,2.52a.533.533,0,0,0,.353.3l2.621.4a.456.456,0,0,1,.3.807L9.993,8.294a.7.7,0,0,0-.151.454L10.3,11.47a.506.506,0,0,1-.706.554L7.22,10.714a.378.378,0,0,0-.454,0L4.4,12.024a.486.486,0,0,1-.706-.554l.454-2.722a.508.508,0,0,0-.151-.454L2.079,6.328a.524.524,0,0,1,.3-.857L5,5.068a.432.432,0,0,0,.353-.3l1.159-2.52A.547.547,0,0,1,7.472,2.3Z"
      transform="translate(-1.956 -1.987)"
      fill="#ffc107"
    />
  </svg>
);

export default StarIcon;
