import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  applyJobRes: '',
  allJobsRes: '',
  getJobByIdRes: '',
};

const jobSlice = createSlice({
  name: 'jobs',
  initialState,
  reducers: {
    applyJobResponse: (state, action) => {
      state.applyJobRes = action.payload;
    },
    allJobsResponse: (state, action) => {
      state.allJobsRes = action.payload;
    },
    getJobByIdResponse: (state, action) => {
      state.getJobByIdRes = action.payload;
    },
  },
});

export const { applyJobResponse, allJobsResponse, getJobByIdResponse } = jobSlice.actions;

export default jobSlice.reducer;
