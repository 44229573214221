const LinkedinIcon = () => (
  <svg id="linkedin" width="14.187" height="14.188" viewBox="0 0 14.187 14.188">
    <path
      id="Path_7"
      data-name="Path 7"
      d="M17.655,17.284h0v-5.2c0-2.545-.548-4.506-3.524-4.506A3.089,3.089,0,0,0,11.352,9.1h-.041V7.812H8.489v9.472h2.938v-4.69c0-1.235.234-2.429,1.763-2.429,1.507,0,1.529,1.409,1.529,2.508v4.612Z"
      transform="translate(-3.471 -3.097)"
      fill="#fff"
    ></path>
    <path
      id="Path_8"
      data-name="Path 8"
      d="M.4,7.977H3.338v9.472H.4Z"
      transform="translate(-0.162 -3.261)"
      fill="#fff"
    ></path>
    <path
      id="Path_9"
      data-name="Path 9"
      d="M1.7,0a1.712,1.712,0,1,0,1.7,1.7A1.7,1.7,0,0,0,1.7,0Z"
      fill="#fff"
    ></path>
  </svg>
);

export default LinkedinIcon;
