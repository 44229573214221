import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  tutorInfo: {},
  upcomingEvent: [],
  earning: [],
  studentDetails: [],
  notes: [],
  correctionData: [],
  enrolledCourses: [],
  getMessage: [],
  getSlot: [],
  getCourseDetails: {},
  getSyllabusDetails: [],
  getTutorResources: [],
  enrollStatus: false,
  tutorLinks: [],
  allStudentData: [],
  getPriorityHoursData: [],
  getVisibleStudentList: [],
  studentReservationTimeSlot: [],
  studentReservationData: [],
  teacherVisibilityStatus: true,
  teacherSurveyComment: [],
  teacherCallLogData: [],
  teacherHistoryCounter: {},
  audioEnableDisable: false,
  videoEnableDisable: false,
  teacherCallLeave: false,
  teacher_private_notes: [],
  get_student_basic_info: {},
  upcoming_Lesson_data: [],
  priority_hours:[],
  school_enroll_course_details:[],
  messageObjTeacher:{},
  setMsgObjTutor:{},
  setChatRequestId:"",
};

const tutorInfoSlice = createSlice({
  name: 'tutorInfo',
  initialState,
  reducers: {
    getTeacherProfileInfo: (state, action) => {
      state.tutorInfo = action.payload;
      // console.warn('action.payload',action.payload);
    },
    getUpcomingEvent: (state, action) => {
      // console.warn('action.payload',action.payload);
      state.upcomingEvent = action.payload;
    },
    getEarning: (state, action) => {
      // console.warn('action.payload',action.payload);
      state.earning = action.payload;
    },
    studentDetailsData: (state, action) => {
      // console.warn('action.studentDetailsDatapayload',action.payload);
      state.studentDetails = action.payload;
    },
    studentNotesData: (state, action) => {
      // console.warn('actionstudentNotesData.payload',action.payload);
      state.notes = action.payload;
    },
    correctionData: (state, action) => {
      // console.warn('actioncorrectionData.payload',action.payload);
      state.correctionData = action.payload;
    },
    EnrolledCoursesData: (state, action) => {
      //  console.warn('EnrolledCoursesData.payload',action.payload);
      state.enrolledCourses = action.payload;
    },
    getMessage: (state, action) => {
      // console.warn('getMessage.payload',action.payload);
      state.getMessage = action.payload;
    },
    getSlot: (state, action) => {
      // console.warn('getMessage.payload',action.payload);
      state.getSlot = action.payload;
    },
    getCourseDetails: (state, action) => {
      // console.warn('getMessage.payload',action.payload);
      state.getCourseDetails = action.payload;
    },
    getSyllabusDetails: (state, action) => {
      // console.warn('getMessage.payload', action.payload);
      state.getSyllabusDetails = action.payload;
    },
    getTutorResources: (state, action) => {
      // console.warn('getMessage.payload', action.payload);
      state.getTutorResources = action.payload;
    },
    enrollStatus: (state, action) => {
      // console.warn('getMessage.payload', action.payload);
      state.enrollStatus = action.payload;
    },
    tutorLinks: (state, action) => {
      // console.warn('getMessage.payload', action.payload);
      state.tutorLinks = action.payload;
    },
    allStudentData: (state, action) => {
      // console.warn('getMessage.payload', action.payload);
      state.allStudentData = action.payload;
    },
    getPriorityHoursData: (state, action) => {
      // console.warn('getMessage.payload', action.payload);
      state.getPriorityHoursData = action.payload;
    },
    getVisibleStudentList: (state, action) => {
      // console.warn('getMessage.payload', action.payload);
      state.getVisibleStudentList = action.payload;
    },
    studentReservationTimeSlot: (state, action) => {
      // console.warn('getMessage.payload', action.payload);
      state.studentReservationTimeSlot = action.payload;
    },
    studentReservationData: (state, action) => {
      // console.warn('getMessage.payload', action.payload);
      state.studentReservationData = action.payload;
    },
    teacherVisibilityStatus: (state, action) => {
      state.studentReservationData = action.payload;
    },
    teacherSurveyComment: (state, action) => {
      // console.warn('getMessage.payload', action.payload);
      state.teacherSurveyComment = action.payload;
    },
    teacherCallLogData: (state, action) => {
      console.warn('getMessage.payload', action.payload);
      state.teacherCallLogData = action.payload;
    },
    teacherHistoryCounter: (state, action) => {
      console.warn('getMessage.payload', action.payload);
      state.teacherHistoryCounter = action.payload;
    },
    videoEnableDisable: (state, action) => {
      state.videoEnableDisable = action.payload;
    },
    audioEnableDisable: (state, action) => {
      state.audioEnableDisable = action.payload;
    },
    teacherCallLeave: (state, action) => {
      state.teacherCallLeave = action.payload;
    },
    teacher_private_notes: (state, action) => {
      state.teacher_private_notes = action.payload;
    },

    get_student_basic_info: (state, action) => {
      state.get_student_basic_info = action.payload;
    },
    upcoming_Lesson_data: (state, action) => {
      state.upcoming_Lesson_data = action.payload;
    },
    priority_hours: (state, action) => {
      state.priority_hours = action.payload;
    },
    school_enroll_course_details: (state, action) => {
      state.school_enroll_course_details = action.payload;
    },
    setMessageObjTeacher: (state, action) => {
      state.messageObjTeacher = action.payload;
    },
    setMessageObjTutor: (state, action) => {
      state.setMsgObjTutor = action.payload;
    },
    tutorChatReqId: (state, action) => {
      state.setChatRequestId = action.payload;
    },
  },
});

export const {
  getTeacherProfileInfo,
  getUpcomingEvent,
  getEarning,
  studentDetailsData,
  studentNotesData,
  correctionData,
  EnrolledCoursesData,
  getMessage,
  getSlot,
  getCourseDetails,
  getSyllabusDetails,
  getTutorResources,
  enrollStatus,
  tutorLinks,
  allStudentData,
  getPriorityHoursData,
  getVisibleStudentList,
  studentReservationTimeSlot,
  studentReservationData,
  teacherVisibilityStatus,
  teacherSurveyComment,
  teacherCallLogData,
  teacherHistoryCounter,
  audioEnableDisable,
  videoEnableDisable,
  teacherCallLeave,
  teacher_private_notes,
  get_student_basic_info,
  upcoming_Lesson_data,
  priority_hours,
  setMessageObjTeacher,
  setMessageObjTutor,
  tutorChatReqId,
  school_enroll_course_details
} = tutorInfoSlice.actions;

export default tutorInfoSlice.reducer;
