const FeaturedCheckIcon = () => (
  <svg className="me-2" width="19.567" height="14.157" viewBox="0 0 19.567 14.157">
    <path
      id="Icon_feather-check"
      data-name="Icon feather-check"
      d="M21.324,9,10.789,19.535,6,14.747"
      transform="translate(-3.879 -6.879)"
      fill="none"
      stroke="#f66962"
      stroke-linecap="round"
      stroke-linejoin="round"
      strokeWidth="3"
    />
  </svg>
);

export default FeaturedCheckIcon;
