const PlusIcon = () => (
  <svg width="23.788" height="23.788" viewBox="0 0 23.788 23.788">
    <path
      id="Icon_material-add-circle"
      data-name="Icon material-add-circle"
      d="M14.894,3A11.894,11.894,0,1,0,26.788,14.894,11.9,11.9,0,0,0,14.894,3Zm5.947,13.083H16.083v4.758H13.7V16.083H8.947V13.7H13.7V8.947h2.379V13.7h4.758Z"
      transform="translate(-3 -3)"
      fill="#fff"
    />
  </svg>
);

export default PlusIcon;
