const EyeIcon = () => (
  <svg width="17.039" height="9.76" viewBox="0 0 17.039 9.76">
    <g id="noun_Eye_2885546" opacity="0.56">
      <path
        id="Path_25285"
        data-name="Path 25285"
        d="M13.52,22c5.153,0,8.52,4.88,8.52,4.88s-3.366,4.88-8.52,4.88S5,26.88,5,26.88,8.366,22,13.52,22Zm0,1.394a3.644,3.644,0,0,1,3.786,3.486,3.644,3.644,0,0,1-3.786,3.486A3.644,3.644,0,0,1,9.733,26.88a3.644,3.644,0,0,1,3.786-3.486Zm0,1.569a1.924,1.924,0,1,1,0,3.834,1.924,1.924,0,1,1,0-3.834Z"
        transform="translate(-5 -22)"
        fillRule="evenodd"
        opacity="0.61"
      />
    </g>
  </svg>
);

export default EyeIcon;
