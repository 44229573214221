const CurrentBalanceIcon = () => (
  <svg width="52.374" height="52.382" viewBox="0 0 52.374 52.382">
    <g id="wallet-passes-app" transform="translate(-0.038)">
      <path
        id="Path_43911"
        data-name="Path 43911"
        d="M26.176,134.274V112.336a8.186,8.186,0,0,1-6.121-7.43l-.017.017v29.35a10.237,10.237,0,0,0,10.237,10.237h6.138A10.237,10.237,0,0,1,26.176,134.274Z"
        transform="translate(-17.955 -94.175)"
        fill="#e4fff0"
      />
      <path
        id="Path_43912"
        data-name="Path 43912"
        d="M50.366,14.431a2.046,2.046,0,0,0,2.046-2.046V10.237A10.249,10.249,0,0,0,42.175,0S10.1,0,10.026.014A10.444,10.444,0,0,0,2.864,3.225,10.084,10.084,0,0,0,.044,10.591C.04,10.643.038,40.1.038,40.1A12.3,12.3,0,0,0,12.321,52.382H42.175A10.249,10.249,0,0,0,52.412,42.145V26.612A10.249,10.249,0,0,0,42.175,16.375H10.267A6.126,6.126,0,0,1,4.136,10.53,6.03,6.03,0,0,1,5.824,6.05,6.331,6.331,0,0,1,10.383,4.1l31.791-.008a6.152,6.152,0,0,1,6.145,6.145v2.148a2.046,2.046,0,0,0,2.046,2.046Zm-40.1,6.035H42.175a6.152,6.152,0,0,1,6.145,6.145V42.145a6.152,6.152,0,0,1-6.145,6.145H12.321A8.2,8.2,0,0,1,4.129,40.1V18.429a10.2,10.2,0,0,0,6.137,2.038Z"
        fill="#67dd9c"
      />
      <path
        id="Path_43913"
        data-name="Path 43913"
        d="M116.046,106.263a2.557,2.557,0,1,1-2.557-2.557A2.557,2.557,0,0,1,116.046,106.263Zm0-24.141A2.046,2.046,0,0,0,114,80.076H82.085a2.046,2.046,0,1,0,0,4.092H114A2.046,2.046,0,0,0,116.046,82.122Z"
        transform="translate(-71.818 -71.885)"
        fill="#67dd9c"
      />
    </g>
  </svg>
);

export default CurrentBalanceIcon;
