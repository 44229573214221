import React, { Fragment } from 'react';

const GreenPlusIcon = () => {
  return (
    <Fragment>
      <svg width="37.063" height="37.063" viewBox="0 0 37.063 37.063">
        <path
          id="add_1_"
          data-name="add (1)"
          d="M22.5,15.222a.662.662,0,0,1-.662-.662V0H15.222V14.561a.662.662,0,0,1-.662.662H0v6.618H14.561a.662.662,0,0,1,.662.662V37.063h6.618V22.5a.662.662,0,0,1,.662-.662H37.063V15.222Zm0,0"
          fill="#67dd9c"
        />
      </svg>
    </Fragment>
  );
};

export default GreenPlusIcon;
