const YoutubeIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="7.275" height="8.394" viewBox="0 0 7.275 8.394">
    <path
      id="Path_36169"
      data-name="Path 36169"
      d="M53.5,49v8.394l7.275-4.2Z"
      transform="translate(-53.5 -49)"
      fill="#fff"
    />
  </svg>
);

export default YoutubeIcon;
