import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  dashboardData: {},
  brokerType: '',
  ambassadorInfo: {},
};

const ambassadorSlice = createSlice({
  name: 'ambassador',
  initialState,
  reducers: {
    setDashboardData: (state, action) => {
      state.dashboardData = action.payload;
    },
    setAmbassadorType: (state, action) => {
      state.brokerType = action.payload;
    },
    setAmbassadorInfo: (state, action) => {
      state.ambassadorInfo = action.payload;
    },
  },
});

export const { setDashboardData, setAmbassadorType, setAmbassadorInfo } = ambassadorSlice.actions;

export default ambassadorSlice.reducer;
