const RightArrowIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="27.981"
    height="14.587"
    viewBox="0 0 27.981 14.587"
  >
    <g id="Layer_2" data-name="Layer 2" transform="translate(-2.06 -8.704)">
      <path
        id="Path_36266"
        data-name="Path 36266"
        d="M22,9a1,1,0,0,0,0,1.42l4.6,4.6H3.06a1,1,0,1,0,0,2H26.58L22,21.59A1,1,0,0,0,23.41,23l6.36-6.36a.88.88,0,0,0,0-1.27L23.42,9A1,1,0,0,0,22,9Z"
        fill="#f66962"
      />
    </g>
  </svg>
);

export default RightArrowIcon;
