import React, { Fragment } from 'react';

const RedCrossIcon = () => {
  return (
    <Fragment>
      <svg width="11.064" height="11.063" viewBox="0 0 11.064 11.063">
        <path
          id="_1828778"
          data-name="1828778"
          d="M6.545,5.667l4.308-4.308A.717.717,0,0,0,9.84.346L5.532,4.654,1.224.346A.717.717,0,0,0,.21,1.359L4.518,5.667.21,9.976a.717.717,0,1,0,1.014,1.014L5.532,6.681,9.84,10.989a.717.717,0,0,0,1.014-1.014Zm0,0"
          transform="translate(0 -0.136)"
          fill="#f66962"
        ></path>
      </svg>
    </Fragment>
  );
};

export default RedCrossIcon;
