import { Link } from 'react-router-dom';

// svg imports
import {
  // YoutubeIcon,
  FacebookIcon,
  InstagramIcon,
  // LinkedinIcon,
  TwitterIcon,
} from '@svg';
import { LinkedinIcon, YoutubeIcon } from '../../../../assets/svg';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer
      className="mt-0"
    >
      <div className="container">
        <div className="d-flex justify-content-between align-items-center py-3">
          <ul className="list-unstyled d-flex mb-0">
            <li className="mb-0">
              <Link to="/privacy-policy" className="text_black f_14 me-3">
                {t('BecomeTutor.Privacy_Policy')}
              </Link>
            </li>
            <li className="mb-0">
              <Link to="/terms&conditions" className="text_black f_14 me-3">
                {t('BecomeTutor.Terms_And_Conditions')}
              </Link>
            </li>
            <li className="mb-0">
              <Link to="/jobs" className="text_black f_14 me-3">
                {t('BecomeTutor.Jobs')}
              </Link>
            </li>
            <li className="mb-0">
              <Link to="/contact-us" className="text_black f_14 me-3">
                {t('BecomeTutor.Contact')}
              </Link>
            </li>
          </ul>
          <ul className="list-unstyled d-flex social_btns mb-0">
            <li className="mb-0">
              <Link to="/" className="text_black f_16">
                <span className="google_btn">
                  <YoutubeIcon />
                </span>
              </Link>
            </li>

            <li className="mb-0">
              <a href="https://www.facebook.com/Ni.Hao.World.Site" className="text_black f_16">
                <span className="fb_btn">
                  <FacebookIcon />
                </span>
              </a>
            </li>

            <li className="mb-0">
              <a href="https://www.instagram.com/ni_hao_world" className="text_black f_16">
                <span className="insta_btn">
                  <InstagramIcon />
                </span>
              </a>
            </li>

            <li className="mb-0">
              <Link to="/" className="text_black f_16">
                <span className="linkedin_btn">
                  <LinkedinIcon />
                </span>
              </Link>
            </li>

            <li className="mb-0">
              <a href="https://twitter.com/Ni_Hao_World" className="text_black f_16">
                <span className="twitter_btn">
                  <TwitterIcon />
                </span>
              </a>
            </li>
          </ul>
          <p className="text_black mb-0 f_14">
            @Copyright By <span className="fw_che_regular text_blue2">Ni-Hao-World</span> &nbsp; |
            &nbsp; {t('BecomeTutor.ALL_RIGHT_RESERVED')}
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
