const SubmitRightIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="34.207"
    height="17.832"
    viewBox="0 0 34.207 17.832"
  >
    <g id="Layer_2" data-name="Layer 2" transform="translate(-2.06 -8.704)">
      <path
        id="Path_36154"
        data-name="Path 36154"
        d="M26.437,9.066a1.223,1.223,0,0,0,0,1.736l5.624,5.624H3.283a1.223,1.223,0,1,0,0,2.445H32.036l-5.6,5.587a1.219,1.219,0,0,0,1.724,1.724l7.775-7.775a1.076,1.076,0,0,0,0-1.553L28.173,9.066a1.223,1.223,0,0,0-1.736,0Z"
        transform="translate(0 0)"
        fill="#fff"
      />
    </g>
  </svg>
);

export default SubmitRightIcon;
