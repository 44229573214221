const InstagramIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10.553"
    height="10.553"
    viewBox="0 0 10.553 10.553"
  >
    <g id="iconfinder_2959748_instagram_photo_share_icon" transform="translate(-15 -15)">
      <path
        id="Path_36170"
        data-name="Path 36170"
        d="M22.322,15.861a2.369,2.369,0,0,1,2.369,2.369v4.092a2.369,2.369,0,0,1-2.369,2.369H18.23a2.369,2.369,0,0,1-2.369-2.369V18.23a2.369,2.369,0,0,1,2.369-2.369h4.092m0-.861H18.23A3.24,3.24,0,0,0,15,18.23v4.092a3.24,3.24,0,0,0,3.23,3.23h4.092a3.24,3.24,0,0,0,3.23-3.23V18.23A3.24,3.24,0,0,0,22.322,15Z"
        fill="#fff"
      />
      <path
        id="Path_36171"
        data-name="Path 36171"
        d="M85.027,32a.617.617,0,1,0,.617.617A.617.617,0,0,0,85.027,32Z"
        transform="translate(-61.936 -15.169)"
        fill="#fff"
      />
      <path
        id="Path_36172"
        data-name="Path 36172"
        d="M41.4,39.331a1.9,1.9,0,1,1-1.9,1.9,1.9,1.9,0,0,1,1.9-1.9m0-.861a2.765,2.765,0,1,0,1.958.808A2.765,2.765,0,0,0,41.4,38.47Z"
        transform="translate(-21.094 -20.943)"
        fill="#fff"
      />
    </g>
  </svg>
);

export default InstagramIcon;
