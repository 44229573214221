const NotificationSettingsIcon = () => (
  <svg className="fill_svg" width="17.192" height="20.317" viewBox="0 0 17.192 20.317">
    <path
      id="Icon_ionic-md-notifications-outline"
      data-name="Icon ionic-md-notifications-outline"
      d="M14.27,6.914a7.061,7.061,0,0,1,1.168.219,4.977,4.977,0,0,1,3.794,4.877v6.233l.455.457.38.382H8.375l.38-.382.455-.457V12.01A4.977,4.977,0,0,1,13,7.133a8.646,8.646,0,0,1,1.168-.219m.049-3.539A1.493,1.493,0,0,0,12.7,4.9V5.61a6.584,6.584,0,0,0-5.056,6.4V17.6L5.625,19.629v1.016H22.817V19.629L20.794,17.6V12.01a6.584,6.584,0,0,0-5.056-6.4V4.9a1.493,1.493,0,0,0-1.517-1.524Zm2.023,18.286H12.2a2.023,2.023,0,1,0,4.045,0Z"
      transform="translate(-5.625 -3.375)"
      fill="#2c6384"
    />
  </svg>
);

export default NotificationSettingsIcon;
