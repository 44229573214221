const UserIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11.631"
    height="12.897"
    viewBox="0 0 11.631 12.897"
  >
    <g id="Icon_feather-user" data-name="Icon feather-user" transform="translate(-5.25 -3.75)">
      <path
        id="Path_44143"
        data-name="Path 44143"
        d="M16.131,26.3V25.033A2.533,2.533,0,0,0,13.6,22.5H8.533A2.533,2.533,0,0,0,6,25.033V26.3"
        transform="translate(0 -10.402)"
        fill="none"
        stroke="#009ff4"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        id="Path_44144"
        data-name="Path 44144"
        d="M17.065,7.033A2.533,2.533,0,1,1,14.533,4.5,2.533,2.533,0,0,1,17.065,7.033Z"
        transform="translate(-3.467 0)"
        fill="none"
        stroke="#009ff4"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </g>
  </svg>
);

export default UserIcon;
