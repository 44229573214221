import { createSlice } from '@reduxjs/toolkit';
import { languages } from 'country-data';

const initialState = {
 all_blogs:[],
 languagesSelector:''
};

const blogSlice = createSlice({
  name: 'blog',
  initialState,
  reducers: {
    all_blogs: (state, action) => {
      state.all_blogs = action.payload;
    },
    languagesSelector: (state, action) => {
        state.languagesSelector = action.payload;
      },
  },
});

export const { all_blogs,languagesSelector } = blogSlice.actions;

export default blogSlice.reducer;
