const UploadIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15.122"
    height="18.362"
    viewBox="0 0 15.122 18.362"
  >
    <path
      id="Icon_metro-file-upload"
      data-name="Icon metro-file-upload"
      d="M13.318,20.468H19.8V13.988h4.321L16.558,6.427,9,13.988h4.321ZM9,22.629H24.119v2.16H9Z"
      transform="translate(-8.997 -6.427)"
      fill="#fff"
    />
  </svg>
);

export default UploadIcon;
