const CommentIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11.612"
    height="11.493"
    viewBox="0 0 11.612 11.493"
  >
    <path
      id="chat"
      d="M10.194,1H3A1.133,1.133,0,0,0,1.885,2.144V3.7H1.118A1.133,1.133,0,0,0,0,4.846v4.08A1.133,1.133,0,0,0,1.118,10.07H2.262V12a.193.193,0,0,0,.121.18.185.185,0,0,0,.207-.05L4.42,10.07H8.309A1.133,1.133,0,0,0,9.427,8.926V7.369h.767a1.133,1.133,0,0,0,1.118-1.144V2.144A1.133,1.133,0,0,0,10.194,1ZM9.049,8.926a.751.751,0,0,1-.741.758H4.336a.187.187,0,0,0-.14.063L2.639,11.5V9.877a.191.191,0,0,0-.189-.193H1.118a.751.751,0,0,1-.741-.758V4.846a.751.751,0,0,1,.741-.758H8.309a.751.751,0,0,1,.741.758v4.08Zm1.885-2.7a.751.751,0,0,1-.741.758H9.427V4.846A1.133,1.133,0,0,0,8.309,3.7H2.262V2.144A.751.751,0,0,1,3,1.386h7.191a.751.751,0,0,1,.741.758Z"
      transform="translate(0.15 -0.85)"
      fill="#fff"
      stroke="#fff"
      strokeWidth="0.3"
    />
  </svg>
);

export default CommentIcon;
