const EditpenProfileCard = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16.091"
    height="16.218"
    viewBox="0 0 16.091 16.218"
  >
    <g id="Icon_feather-edit" data-name="Icon feather-edit" transform="translate(-2.5 -2.191)">
      <path
        id="Path_28843"
        data-name="Path 28843"
        d="M9.75,6H4.5A1.5,1.5,0,0,0,3,7.5V18a1.5,1.5,0,0,0,1.5,1.5H15A1.5,1.5,0,0,0,16.5,18V12.75"
        transform="translate(0 -1.591)"
        fill="none"
        stroke="#0a9ef8"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      ></path>
      <path
        id="Path_28844"
        data-name="Path 28844"
        d="M19.875,3.284a1.591,1.591,0,0,1,2.25,2.25L15,12.659l-3,.75.75-3Z"
        transform="translate(-4.5)"
        fill="none"
        stroke="#0a9ef8"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      ></path>
    </g>
  </svg>
);

export default EditpenProfileCard;
