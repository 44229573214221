import React, { Fragment } from 'react';

const RedCalendarIcon = () => {
  return (
    <Fragment>
      <svg width="14.85" height="14.424" viewBox="0 0 14.85 14.424">
        <g
          id="Icon_feather-calendar"
          data-name="Icon feather-calendar"
          transform="translate(0.5 0.5)"
        >
          <path
            id="Path_36009"
            data-name="Path 36009"
            d="M6.039,6H16.811A1.451,1.451,0,0,1,18.35,7.342v9.4a1.451,1.451,0,0,1-1.539,1.342H6.039A1.451,1.451,0,0,1,4.5,16.74v-9.4A1.451,1.451,0,0,1,6.039,6Z"
            transform="translate(-4.5 -4.658)"
            fill="none"
            stroke="#f66962"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1}
          />
          <path
            id="Path_36010"
            data-name="Path 36010"
            d="M24,3V5.685"
            transform="translate(-13.998 -3)"
            fill="none"
            stroke="#f66962"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1}
          />
          <path
            id="Path_36011"
            data-name="Path 36011"
            d="M12,3V5.685"
            transform="translate(-8.153 -3)"
            fill="none"
            stroke="#f66962"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1}
          />
          <path
            id="Path_36012"
            data-name="Path 36012"
            d="M4.5,15H18.35"
            transform="translate(-4.5 -9.63)"
            fill="none"
            stroke="#f66962"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1}
          />
        </g>
      </svg>
    </Fragment>
  );
};

export default RedCalendarIcon;
