const StandardClockIcon = () => (
  <svg width="13.83" height="13.83" viewBox="0 0 13.83 13.83">
    <g id="Icon_ionic-md-time" data-name="Icon ionic-md-time" opacity="0.656">
      <path
        id="Path_36025"
        data-name="Path 36025"
        d="M10.283,3.375a6.915,6.915,0,1,0,6.922,6.915A6.913,6.913,0,0,0,10.283,3.375Zm.007,12.447a5.532,5.532,0,1,1,5.532-5.532A5.532,5.532,0,0,1,10.29,15.822Z"
        transform="translate(-3.375 -3.375)"
        fill="#2c6384"
      />
      <path
        id="Path_36026"
        data-name="Path 36026"
        d="M17.575,10.688H16.538v4.149l3.63,2.178.519-.851-3.112-1.845Z"
        transform="translate(-10.314 -7.23)"
        fill="#2c6384"
      />
    </g>
  </svg>
);

export default StandardClockIcon;
