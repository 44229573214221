const GlobalIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="145" height="145" viewBox="0 0 145 145">
    <g id="Group_41547" data-name="Group 41547" transform="translate(-87.119 -3)">
      <rect
        id="Rectangle_5243"
        data-name="Rectangle 5243"
        width="145"
        height="145"
        rx="72.5"
        transform="translate(87.119 3)"
        fill="#fff"
      />
      <g
        id="Rectangle_5244"
        data-name="Rectangle 5244"
        transform="translate(98.119 13)"
        fill="#fff"
        stroke="#414143"
        strokeWidth="1"
      >
        <rect width="124" height="124" rx="62" stroke="none" />
        <rect x="0.5" y="0.5" width="123" height="123" rx="61.5" fill="none" />
      </g>
      <path
        id="_2135789_earth_language_planet_icon"
        data-name="2135789_earth_language_planet_icon"
        d="M44.516,6A38.516,38.516,0,1,0,83.031,44.516,38.51,38.51,0,0,0,44.516,6Zm-4.976,4.391a53.775,53.775,0,0,0-8.658,15.36,51.479,51.479,0,0,1-11.632-4.714A34.4,34.4,0,0,1,39.539,10.391ZM17.524,23.055a53.85,53.85,0,0,0,12.525,5.161,54.886,54.886,0,0,0-2.45,14.99H10.052A34.313,34.313,0,0,1,17.524,23.055ZM10.052,45.81H27.6a54.79,54.79,0,0,0,2.45,14.99,54.526,54.526,0,0,0-12.525,5.161A34.237,34.237,0,0,1,10.052,45.81Zm9.2,22.17a52.643,52.643,0,0,1,11.632-4.714,54.308,54.308,0,0,0,8.658,15.36A34.312,34.312,0,0,1,19.249,67.979ZM43.222,78.964a52.187,52.187,0,0,1-9.8-16.315,52.383,52.383,0,0,1,9.8-1.171Zm0-20.074a55.237,55.237,0,0,0-10.646,1.294A52.18,52.18,0,0,1,30.2,45.81H43.222Zm0-15.668H30.2a52.18,52.18,0,0,1,2.373-14.374,53.915,53.915,0,0,0,10.646,1.294Zm0-15.684a52.692,52.692,0,0,1-9.8-1.171,51.612,51.612,0,0,1,9.8-16.315ZM78.979,43.222H61.416a55.262,55.262,0,0,0-2.45-14.99,54.521,54.521,0,0,0,12.556-5.176A34.408,34.408,0,0,1,78.979,43.222Zm-9.2-22.17a51.422,51.422,0,0,1-11.663,4.714,54.36,54.36,0,0,0-8.643-15.375A34.327,34.327,0,0,1,69.782,21.052ZM45.825,10.067a51.8,51.8,0,0,1,9.768,16.315,53.53,53.53,0,0,1-9.768,1.171Zm0,20.074A53.748,53.748,0,0,0,56.44,28.847a51.873,51.873,0,0,1,2.373,14.359H45.825Zm0,15.668H58.813A52.451,52.451,0,0,1,56.44,60.168a56,56,0,0,0-10.615-1.294Zm0,15.684a50.96,50.96,0,0,1,9.768,1.171,51.8,51.8,0,0,1-9.768,16.315ZM49.492,78.64a54.36,54.36,0,0,0,8.643-15.375A51.709,51.709,0,0,1,69.8,67.979,34.416,34.416,0,0,1,49.492,78.64ZM71.507,65.977A54.521,54.521,0,0,0,58.951,60.8,55.165,55.165,0,0,0,61.4,45.81H78.964A34.2,34.2,0,0,1,71.507,65.977Z"
        transform="translate(115.419 30.57)"
        fill="#414143"
        stroke="#fff"
        strokeWidth="1"
      />
    </g>
  </svg>
);

export default GlobalIcon;
