const ChinaFlagIcon = () => (
  <svg
    className="me-1"
    id="_2151303"
    data-name="2151303"
    width="24.829"
    height="17.308"
    viewBox="0 0 24.829 17.308"
  >
    <path
      id="Path_28512"
      data-name="Path 28512"
      d="M2.979,6.89H27.808V24.2H2.979Z"
      transform="translate(-2.979 -6.89)"
      fill="#d60a2e"
    />
    <g id="Group_37097" data-name="Group 37097" transform="translate(1.321 1.307)">
      <path
        id="Path_28513"
        data-name="Path 28513"
        d="M7.17,10.174l.87,1.763,1.946.283L8.577,13.592,8.91,15.53l-1.74-.915-1.741.915.333-1.938L4.353,12.22,6.3,11.937Z"
        transform="translate(-4.353 -8.323)"
        fill="#fed953"
      />
      <path
        id="Path_28514"
        data-name="Path 28514"
        d="M12.6,13.693l.348.7.778.113-.563.549.133.774-.7-.366-.7.366.133-.774-.562-.549.778-.113Z"
        transform="translate(-4.627 -8.459)"
        fill="#fed953"
      />
      <path
        id="Path_28515"
        data-name="Path 28515"
        d="M12.19,10.49l.636.462.741-.261-.243.747.478.624h-.786l-.446.647-.242-.747-.753-.224.636-.461Z"
        transform="translate(-4.631 -8.335)"
        fill="#fed953"
      />
      <path
        id="Path_28516"
        data-name="Path 28516"
        d="M11.081,8.277l-.148.772.551.56-.78.1-.362.7-.333-.711-.775-.129.574-.537L9.69,8.249l.688.38Z"
        transform="translate(-4.541 -8.249)"
        fill="#fed953"
      />
      <path
        id="Path_28517"
        data-name="Path 28517"
        d="M11.081,16.5l-.148.772.551.56-.78.1-.362.7-.333-.711-.775-.129.574-.537-.117-.777.688.38Z"
        transform="translate(-4.541 -8.565)"
        fill="#fed953"
      />
    </g>
  </svg>
);

export default ChinaFlagIcon;
