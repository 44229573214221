const Calendar = () => (
  <svg width="16.221" height="18.023" viewBox="0 0 16.221 18.023">
    <path
      id="Icon_material-date-range"
      data-name="Icon material-date-range"
      d="M17.116,12.913Zm1.8-8.11h-.9V3h-1.8V4.8H9.006V3H7.2V4.8H6.3A1.794,1.794,0,0,0,4.509,6.6L4.5,19.221a1.8,1.8,0,0,0,1.8,1.8H18.919a1.808,1.808,0,0,0,1.8-1.8V6.6A1.808,1.808,0,0,0,18.919,4.8Zm0,14.419H6.3V9.308H18.919Z"
      transform="translate(-4.5 -3)"
      fill="#005182"
    />
  </svg>
);

export default Calendar;
