const BackIcon = () => (
  <svg
    id="right-arrow_12_"
    data-name="right-arrow (12)"
    width="18.633"
    height="9.025"
    viewBox="0 0 18.633 9.025"
  >
    <g id="Group_37160" data-name="Group 37160" transform="translate(0 0)">
      <path
        id="Path_28731"
        data-name="Path 28731"
        d="M.213,136h0l3.8-3.785a.728.728,0,0,1,1.027,1.032l-2.553,2.541H17.905a.728.728,0,0,1,0,1.456H2.491l2.553,2.541a.728.728,0,0,1-1.027,1.032l-3.8-3.785h0A.728.728,0,0,1,.213,136Z"
        transform="translate(0 -132)"
        fill="#005182"
      ></path>
    </g>
  </svg>
);

export default BackIcon;
