import { combineReducers } from '@reduxjs/toolkit';

import authUser from './authUserReducer';
import tutorInfo from './teacherReducer';
import ambassador from './ambassador.reducer';
import locationInfo from './location.reducer';
import studentInfo from './student.reducer';
import school from './school.reducer';
import company from './companyReducer';
import jobs from './jobs.reducer';
import plans from './plans';
import blogs from './blog.reducer'
import common from "./common.reducer"

const rootReducer = combineReducers({
  authUser,
  tutorInfo,
  ambassador,
  locationInfo,
  studentInfo,
  school,
  company,
  jobs,
  plans,
  blogs,
  common,

});

export default rootReducer;
