const EditPenBasicInfoIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17.961"
    height="17.961"
    viewBox="0 0 17.961 17.961"
  >
    <g
      id="_2561427_edit_icon_1_"
      data-name="2561427_edit_icon (1)"
      transform="translate(-1.35 -1.35)"
    >
      <path
        id="Path_30739"
        data-name="Path 30739"
        d="M17,12.88v4.449A1.666,1.666,0,0,1,15.329,19H3.666A1.666,1.666,0,0,1,2,17.329V5.666A1.666,1.666,0,0,1,3.666,4H8.115"
        transform="translate(0 -0.334)"
        fill="none"
        stroke="#f66962"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.3"
      />
      <path
        id="Path_30740"
        data-name="Path 30740"
        d="M16.331,2l3.332,3.332-8.331,8.331H8V10.331Z"
        transform="translate(-1.002)"
        fill="none"
        stroke="#f66962"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.3"
      />
    </g>
  </svg>
);

export default EditPenBasicInfoIcon;
