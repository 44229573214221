const TutorMessageIcon = () => {
  return (
    <svg
      id="email_2_"
      data-name="email (2)"
      xmlns="http://www.w3.org/2000/svg"
      width="14.338"
      height="10.921"
      viewBox="0 0 14.338 10.921"
    >
      <g id="Group_4" data-name="Group 4">
        <path
          id="Path_116"
          data-name="Path 116"
          d="M13.078,61H1.26A1.262,1.262,0,0,0,0,62.26v8.4a1.262,1.262,0,0,0,1.26,1.26H13.078a1.262,1.262,0,0,0,1.26-1.26v-8.4A1.262,1.262,0,0,0,13.078,61Zm-.174.84L7.2,67.548,1.438,61.84ZM.84,70.487V62.43l4.046,4.011Zm.594.594,4.048-4.048L6.9,68.439a.42.42,0,0,0,.593,0l1.383-1.383L12.9,71.081ZM13.5,70.487,9.471,66.461,13.5,62.434Z"
          transform="translate(0 -61)"
          fill="#fff"
        />
      </g>
    </svg>
  );
};

export default TutorMessageIcon;
