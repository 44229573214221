const InfoIcon = () => (
  <svg id="info" width="19.661" height="19.661" viewBox="0 0 19.661 19.661">
    <g id="Group_41423" data-name="Group 41423">
      <g id="Group_41422" data-name="Group 41422">
        <path
          id="Path_43964"
          data-name="Path 43964"
          d="M9.831,0a9.831,9.831,0,1,0,9.831,9.831A9.825,9.825,0,0,0,9.831,0Zm0,18.289a8.459,8.459,0,1,1,8.459-8.459A8.468,8.468,0,0,1,9.831,18.289Z"
          fill="#0e83c1"
        />
      </g>
    </g>
    <g id="Group_41425" data-name="Group 41425" transform="translate(8.834 8.195)">
      <g id="Group_41424" data-name="Group 41424">
        <path
          id="Path_43965"
          data-name="Path 43965"
          d="M231.055,213.4c-.582,0-1,.246-1,.608v4.93c0,.311.414.621,1,.621.556,0,1.009-.311,1.009-.621v-4.93C232.065,213.643,231.612,213.4,231.055,213.4Z"
          transform="translate(-230.059 -213.397)"
          fill="#0e83c1"
        />
      </g>
    </g>
    <g id="Group_41427" data-name="Group 41427" transform="translate(8.77 5.154)">
      <g id="Group_41426" data-name="Group 41426">
        <path
          id="Path_43966"
          data-name="Path 43966"
          d="M229.436,134.208a.932.932,0,1,0,1.048.919A1,1,0,0,0,229.436,134.208Z"
          transform="translate(-228.375 -134.208)"
          fill="#0e83c1"
        />
      </g>
    </g>
  </svg>
);

export default InfoIcon;
