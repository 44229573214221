const SettingsIcon = () => (
  <svg className="stroke_svg" width="20.354" height="20.354" viewBox="0 0 20.354 20.354">
    <g id="Icon_feather-settings" data-name="Icon feather-settings" transform="translate(-1 -1)">
      <path
        id="Path_28834"
        data-name="Path 28834"
        d="M18.778,16.139A2.639,2.639,0,1,1,16.139,13.5,2.639,2.639,0,0,1,18.778,16.139Z"
        transform="translate(-4.962 -4.962)"
        fill="none"
        stroke="#005182"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        id="Path_28835"
        data-name="Path 28835"
        d="M17.687,13.816a1.452,1.452,0,0,0,.29,1.6l.053.053a1.76,1.76,0,1,1-2.49,2.49l-.053-.053a1.463,1.463,0,0,0-2.481,1.038v.15a1.759,1.759,0,1,1-3.519,0v-.079a1.452,1.452,0,0,0-.95-1.328,1.452,1.452,0,0,0-1.6.29l-.053.053a1.76,1.76,0,1,1-2.49-2.49l.053-.053a1.463,1.463,0,0,0-1.038-2.481h-.15a1.759,1.759,0,0,1,0-3.519h.079a1.452,1.452,0,0,0,1.328-.95,1.452,1.452,0,0,0-.29-1.6l-.053-.053a1.76,1.76,0,1,1,2.49-2.49l.053.053a1.452,1.452,0,0,0,1.6.29h.07a1.452,1.452,0,0,0,.88-1.328v-.15a1.759,1.759,0,0,1,3.519,0v.079a1.463,1.463,0,0,0,2.481,1.038l.053-.053a1.76,1.76,0,1,1,2.49,2.49l-.053.053a1.452,1.452,0,0,0-.29,1.6v.07a1.452,1.452,0,0,0,1.328.88h.15a1.759,1.759,0,0,1,0,3.519h-.079a1.452,1.452,0,0,0-1.328.88Z"
        fill="none"
        stroke="#005182"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
    </g>
  </svg>
);

export default SettingsIcon;
