const ClearCloseIcon = () => (
  <svg width="12" height="12" className="me-2" viewBox="0 0 9.776 9.776">
    <path
      id="Icon_metro-cross"
      data-name="Icon metro-cross"
      d="M11.249,8.964h0L8.592,6.307,11.249,3.65h0a.274.274,0,0,0,0-.387L9.994,2.008a.274.274,0,0,0-.387,0h0L6.95,4.665,4.293,2.008h0a.274.274,0,0,0-.387,0L2.651,3.263a.274.274,0,0,0,0,.387h0L5.308,6.307,2.651,8.964h0a.274.274,0,0,0,0,.387l1.255,1.255a.274.274,0,0,0,.387,0h0L6.95,7.95l2.657,2.657h0a.274.274,0,0,0,.387,0l1.255-1.255a.274.274,0,0,0,0-.387Z"
      transform="translate(-2.062 -1.419)"
      fill="#f66962"
      stroke="#fff"
      strokeWidth="1"
    />
  </svg>
);

export default ClearCloseIcon;
