const BanIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13.446"
    height="13.446"
    viewBox="0 0 13.446 13.446"
  >
    <path
      id="Icon_awesome-ban"
      data-name="Icon awesome-ban"
      d="M7.286.563a6.723,6.723,0,1,0,6.723,6.723A6.723,6.723,0,0,0,7.286.563Zm3.527,3.2a4.989,4.989,0,0,1,.561,6.388L4.425,3.2a4.989,4.989,0,0,1,6.388.561ZM3.758,10.813A4.989,4.989,0,0,1,3.2,4.425l6.949,6.949a4.989,4.989,0,0,1-6.388-.561Z"
      transform="translate(-0.563 -0.563)"
      fill="#fff"
    />
  </svg>
);

export default BanIcon;
