const PracticePlayIcon = () => (
  <svg width="26" height="26" viewBox="0 0 26 26">
    <g id="Group_41420" data-name="Group 41420" transform="translate(-540 -214)">
      <circle
        id="Ellipse_307"
        data-name="Ellipse 307"
        cx="13"
        cy="13"
        r="13"
        transform="translate(540 214)"
        fill="#67dd9c"
      />
      <path
        id="play"
        d="M26.006,5.223,16.852.074a.572.572,0,0,0-.852.5v10.3a.572.572,0,0,0,.852.5L26.006,6.22a.572.572,0,0,0,0-1Z"
        transform="translate(532.702 221.279)"
        fill="#fff"
      />
    </g>
  </svg>
);

export default PracticePlayIcon;
