const FreeMinutesIcon = () => {
  return (
    <>
      <svg
        id="XMLID_1031_"
        xmlns="http://www.w3.org/2000/svg"
        width="15.791"
        height="15.791"
        viewBox="0 0 15.791 15.791"
      >
        <g id="XMLID_971_" transform="translate(0)">
          <g id="XMLID_977_">
            <path
              id="XMLID_1030_"
              d="M14.106,3.021h.488a.308.308,0,1,0,0-.616H13.423a.308.308,0,0,0-.308.308V3.884a.308.308,0,0,0,.616,0V3.544A7.279,7.279,0,1,1,7.9.616,7.389,7.389,0,0,1,8.784.67.308.308,0,1,0,8.858.058,8.008,8.008,0,0,0,7.9,0,7.9,7.9,0,0,0,2.313,13.478,7.9,7.9,0,0,0,14.106,3.021Z"
              transform="translate(0)"
              fill="#005182"
            />
            <path
              id="XMLID_1029_"
              d="M66.513,67.825a.308.308,0,0,0-.425.1,5.885,5.885,0,1,0,1.842-1.838.308.308,0,0,0,.329.522,5.271,5.271,0,1,1-.92,8.189,5.3,5.3,0,0,1-.73-6.543A.308.308,0,0,0,66.513,67.825Z"
              transform="translate(-63.17 -63.171)"
              fill="#005182"
            />
            <path
              id="XMLID_1028_"
              d="M376.477,246.946h.38a.308.308,0,1,0,0-.616h-.38a.308.308,0,0,0,0,.616Z"
              transform="translate(-364.575 -238.738)"
              fill="#005182"
            />
            <path
              id="XMLID_1004_"
              d="M103.835,246.314a.308.308,0,0,0,.308.308h.38a.308.308,0,1,0,0-.616h-.38A.308.308,0,0,0,103.835,246.314Z"
              transform="translate(-100.635 -238.424)"
              fill="#005182"
            />
            <path
              id="XMLID_1001_"
              d="M246.946,104.523v-.38a.308.308,0,0,0-.616,0v.38a.308.308,0,0,0,.616,0Z"
              transform="translate(-238.738 -100.635)"
              fill="#005182"
            />
            <path
              id="XMLID_1415_"
              d="M246.006,376.477v.38a.308.308,0,1,0,.616,0v-.38a.308.308,0,0,0-.616,0Z"
              transform="translate(-238.424 -364.575)"
              fill="#005182"
            />
            <path
              id="XMLID_996_"
              d="M189.5,155.634a.308.308,0,0,0-.436.436l1.045,1.045a1.062,1.062,0,1,0,1.872,0l2.108-2.108a.308.308,0,0,0-.436-.436l-2.108,2.108a1.059,1.059,0,0,0-1,0Zm1.99,1.981a.445.445,0,1,1-.445-.445A.446.446,0,0,1,191.489,157.615Z"
              transform="translate(-183.148 -149.72)"
              fill="#005182"
            />
            <path
              id="XMLID_980_"
              d="M118.7,118.7a.308.308,0,1,0-.218.09A.309.309,0,0,0,118.7,118.7Z"
              transform="translate(-114.536 -114.536)"
              fill="#005182"
            />
            <path
              id="XMLID_979_"
              d="M315.746,10.494a.308.308,0,1,0-.218-.09A.309.309,0,0,0,315.746,10.494Z"
              transform="translate(-305.716 -9.574)"
              fill="#005182"
            />
          </g>
        </g>
      </svg>
    </>
  );
};

export default FreeMinutesIcon;
