const TwitterIcon = () => (
  <svg width="15.71" height="12.765" viewBox="0 0 15.71 12.765">
    <path
      id="twitter_3_"
      data-name="twitter (3)"
      d="M15.71,49.511a6.715,6.715,0,0,1-1.856.509,3.2,3.2,0,0,0,1.417-1.78,6.436,6.436,0,0,1-2.042.78,3.221,3.221,0,0,0-5.571,2.2,3.316,3.316,0,0,0,.075.734,9.116,9.116,0,0,1-6.639-3.369,3.222,3.222,0,0,0,.99,4.3,3.181,3.181,0,0,1-1.455-.4v.035A3.236,3.236,0,0,0,3.209,55.7a3.215,3.215,0,0,1-.844.106,2.848,2.848,0,0,1-.61-.055,3.251,3.251,0,0,0,3.01,2.244A6.472,6.472,0,0,1,.771,59.363,6.033,6.033,0,0,1,0,59.319a9.067,9.067,0,0,0,4.941,1.445A9.1,9.1,0,0,0,14.108,51.6c0-.142,0-.28-.012-.416A6.425,6.425,0,0,0,15.71,49.511Z"
      transform="translate(0 -48)"
      fill="#fff"
    ></path>
  </svg>
);

export default TwitterIcon;
