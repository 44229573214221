const ContactIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="145" height="145" viewBox="0 0 145 145">
    <g id="Group_41548" data-name="Group 41548" transform="translate(-80 -2)">
      <rect
        id="Rectangle_5245"
        data-name="Rectangle 5245"
        width="145"
        height="145"
        rx="72.5"
        transform="translate(80 2)"
        fill="#fff"
      />
      <g
        id="Rectangle_5246"
        data-name="Rectangle 5246"
        transform="translate(91 13)"
        fill="#fff"
        stroke="#414143"
        strokeWidth="1"
      >
        <rect width="124" height="124" rx="62" stroke="none" />
        <rect x="0.5" y="0.5" width="123" height="123" rx="61.5" fill="none" />
      </g>
      <g
        id="_6570716_class_education_learning_lecture_presentation_icon"
        data-name="6570716_class_education_learning_lecture_presentation_icon"
        transform="translate(114.915 44.916)"
      >
        <path
          id="Path_43713"
          data-name="Path 43713"
          d="M59.386,366.67a.786.786,0,0,0-.786.786v11.375a.786.786,0,0,0,1.573,0V367.456A.786.786,0,0,0,59.386,366.67Z"
          transform="translate(-53.135 -319.59)"
          fill="#414143"
          stroke="#414144"
          strokeWidth="1"
        />
        <path
          id="Path_43714"
          data-name="Path 43714"
          d="M94.186,61.37H25.635a.786.786,0,0,0-.786.786v42.431a12.443,12.443,0,0,0-1.689,6.273v9.751a.786.786,0,1,0,1.573,0v-9.751A10.958,10.958,0,0,1,35.682,99.912H38.16c6.415,0,8.244.637,11.888,1.906l4.7,1.633a.786.786,0,0,0,.84-.213l9.029-9.914a2.2,2.2,0,0,1,2.734-.387,2.159,2.159,0,0,1,.532,3.323L57.012,108.383a2.622,2.622,0,0,1-2.467.833l-7.1-2.276a.785.785,0,0,0-1.027.748v12.924a.786.786,0,1,0,1.573,0v-4.9h46.2a.786.786,0,0,0,.786-.786V62.156A.786.786,0,0,0,94.186,61.37ZM38.043,98.251a7.094,7.094,0,1,1,7.094-7.094A7.094,7.094,0,0,1,38.043,98.251ZM93.4,114.135H48v-5.37l6.15,1.968a4.225,4.225,0,0,0,4.04-1.3L69.058,97.31a3.769,3.769,0,0,0-5.6-5.05l-8.688,9.532-4.207-1.46a27.225,27.225,0,0,0-7.914-1.843,8.662,8.662,0,1,0-9.151.039,12.511,12.511,0,0,0-7.08,3.914v-39.5H93.4Z"
          transform="translate(-23.16 -61.37)"
          fill="#414143"
          stroke="#414144"
          strokeWidth="1"
        />
        <path
          id="Path_43715"
          data-name="Path 43715"
          d="M193,116.283h34.477a.786.786,0,0,0,0-1.573H193a.786.786,0,0,0,0,1.573Z"
          transform="translate(-166.141 -106.484)"
          fill="#414143"
          stroke="#414144"
          strokeWidth="1"
        />
        <path
          id="Path_43716"
          data-name="Path 43716"
          d="M380.527,165.58h-6.571a.786.786,0,0,0,0,1.573h6.571a.786.786,0,1,0,0-1.573Z"
          transform="translate(-319.195 -149.51)"
          fill="#414143"
          stroke="#414144"
          strokeWidth="1"
        />
        <path
          id="Path_43717"
          data-name="Path 43717"
          d="M193,167.153H212.97a.786.786,0,0,0,0-1.573H193a.786.786,0,0,0,0,1.573Z"
          transform="translate(-166.141 -149.51)"
          fill="#414143"
          stroke="#414144"
          strokeWidth="1"
        />
        <path
          id="Path_43718"
          data-name="Path 43718"
          d="M345.884,216.45H333a.786.786,0,1,0,0,1.573h12.887a.786.786,0,1,0,0-1.573Z"
          transform="translate(-284.551 -192.535)"
          fill="#414143"
          stroke="#414144"
          strokeWidth="1"
        />
        <path
          id="Path_43719"
          data-name="Path 43719"
          d="M193,218.023h6.756a.786.786,0,1,0,0-1.573H193a.786.786,0,0,0,0,1.573Z"
          transform="translate(-166.141 -192.535)"
          fill="#414143"
          stroke="#414144"
          strokeWidth="1"
        />
      </g>
    </g>
  </svg>
);

export default ContactIcon;
