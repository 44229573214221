const ActiveHeartIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="17.145" viewBox="0 0 21 17.145">
    <g id="Group_41419" data-name="Group 41419" transform="translate(-480 -346)">
      <path
        id="_1077086"
        data-name="1077086"
        d="M14.271,1.35A4.168,4.168,0,0,0,11.17,0,3.9,3.9,0,0,0,8.735.841,4.983,4.983,0,0,0,7.75,1.869,4.98,4.98,0,0,0,6.766.841,3.9,3.9,0,0,0,4.33,0a4.168,4.168,0,0,0-3.1,1.35A4.845,4.845,0,0,0,0,4.658,5.769,5.769,0,0,0,1.538,8.435a32.781,32.781,0,0,0,3.848,3.612c.533.454,1.138.97,1.765,1.518a.91.91,0,0,0,1.2,0c.628-.549,1.232-1.064,1.766-1.519a32.763,32.763,0,0,0,3.848-3.612A5.769,5.769,0,0,0,15.5,4.658a4.844,4.844,0,0,0-1.23-3.309Zm0,0"
        transform="translate(480.75 348.605) "
        fill="#f66962"
        stroke="#f52e24"
        strokeWidth="1.5"
      />
      <circle
        id="Ellipse_351"
        data-name="Ellipse 351"
        cx="4"
        cy="4"
        r="4"
        transform="translate(493 346)"
        fill="#67dd9c"
      />
    </g>
  </svg>
);

export default ActiveHeartIcon;
