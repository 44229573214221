const DeleteAccountIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="13.202" height="17.5" viewBox="0 0 13.202 17.5">
    <g id="delete-friend" transform="translate(5.346 0.25)">
      <path
        id="Path_28840"
        data-name="Path 28840"
        d="M48.95,362.993a.335.335,0,1,0,.04.426.339.339,0,0,0-.04-.426Zm0,0"
        transform="translate(-52.041 -353.256)"
        fill="#f66962"
        stroke="#f66962"
        strokeWidth="0.5"
      />
      <path
        id="Path_28841"
        data-name="Path 28841"
        d="M5.151,10.417,6.559,7.9a1.147,1.147,0,0,0-.992-1.69H4.038a3.933,3.933,0,0,0,.705-2.251A3.96,3.96,0,0,0,.787,0,3.96,3.96,0,0,0-3.168,3.955a3.932,3.932,0,0,0,.705,2.251H-3.952A1.147,1.147,0,0,0-4.943,7.9l.626,1.121a.336.336,0,0,0,.586-.327l-.626-1.121a.468.468,0,0,1,.406-.691h2.076a3.948,3.948,0,0,0,5.325,0H5.568a.468.468,0,0,1,.406.691L4.445,10.306A3.358,3.358,0,0,0,.959,13.625,3.3,3.3,0,0,0,1.542,15.5l-.328.587a.469.469,0,0,1-.812,0l-2.831-5.069a.336.336,0,0,0-.586.327l2.831,5.069A1.122,1.122,0,0,0,.808,17a1.122,1.122,0,0,0,.992-.582l.208-.372a3.323,3.323,0,1,0,3.144-5.629ZM.787,7.239A3.284,3.284,0,1,1,4.072,3.955,3.288,3.288,0,0,1,.787,7.239Zm3.495,9.038a2.652,2.652,0,1,1,2.653-2.653A2.655,2.655,0,0,1,4.282,16.277Zm0,0"
        fill="#f66962"
        stroke="#f66962"
        strokeWidth="0.5"
      />
      <path
        id="Path_28842"
        data-name="Path 28842"
        d="M300.073,467.44l.7-.7a.336.336,0,0,0-.475-.474l-.7.7-.692-.692a.336.336,0,0,0-.474.474l.692.692-.7.7c-.3.3.171.778.474.474l.7-.7.71.711c.3.3.781-.168.475-.474Zm0,0"
        transform="translate(-295.351 -453.783)"
        fill="#f66962"
        stroke="#f66962"
        strokeWidth="0.5"
      />
    </g>
  </svg>
);

export default DeleteAccountIcon;
