const SearchResourcesIcon = () => (
  <svg width="15.857" height="15.857" viewBox="0 0 15.857 15.857">
    <path
      id="Icon_material-search"
      data-name="Icon material-search"
      d="M15.833,14.473h-.716l-.254-.245a5.9,5.9,0,1,0-.635.635l.245.254v.716l4.533,4.524,1.351-1.351Zm-5.44,0a4.08,4.08,0,1,1,4.08-4.08A4.074,4.074,0,0,1,10.393,14.473Z"
      transform="translate(-4.5 -4.5)"
      fill="#f66962"
    />
  </svg>
);

export default SearchResourcesIcon;
