const NiHaoSphereIcon = () => (
  <svg width="11.24" height="10.758" viewBox="0 0 11.24 10.758">
    <path
      id="Icon_awesome-star"
      data-name="Icon awesome-star"
      d="M6.459.373,5.087,3.155,2.017,3.6A.673.673,0,0,0,1.645,4.75L3.866,6.914,3.341,9.971a.672.672,0,0,0,.975.708L7.062,9.235l2.746,1.443a.672.672,0,0,0,.975-.708l-.525-3.057L12.478,4.75A.673.673,0,0,0,12.106,3.6l-3.07-.448L7.665.373A.673.673,0,0,0,6.459.373Z"
      transform="translate(-1.441 0.001)"
      fill="#f6ba01"
    ></path>
  </svg>
);

export default NiHaoSphereIcon;
