const EditpenIcon = () => (
  <svg width="17.656" height="17.52" viewBox="0 0 17.656 17.52">
    <g id="Icon_feather-edit" data-name="Icon feather-edit" transform="translate(-2.5 -2.318)">
      <path
        id="Path_43964"
        data-name="Path 43964"
        d="M10.389,6H4.642A1.642,1.642,0,0,0,3,7.642V19.136a1.642,1.642,0,0,0,1.642,1.642H16.136a1.642,1.642,0,0,0,1.642-1.642V13.389"
        transform="translate(0 -1.44)"
        fill="none"
        stroke="#f66962"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        id="Path_43965"
        data-name="Path 43965"
        d="M20.621,3.328a1.742,1.742,0,0,1,2.463,2.463l-7.8,7.8L12,14.412l.821-3.284Z"
        transform="translate(-4.074)"
        fill="none"
        stroke="#f66962"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
    </g>
  </svg>
);
export default EditpenIcon;
