import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: {},
  token: null,
  emailForOtp: '',
  forgetPasswordToken: '',
  agoraToken: '',
  fcmToken: '',
};

const authSlice = createSlice({
  name: 'authUser',
  initialState,
  reducers: {
    loginSuccess: (state, action) => {
      state.user = action.payload.user || action.payload.schoolDetails;
      state.token = action.payload.user.token || action.payload.token || action.payload.userToken;
    },
    socialLoginSuccess: (state, action) => {
      state.user = action.payload;
      state.token = action.payload.token;
    },
    setEmailforOtp: (state, action) => {
      state.emailForOtp = action.payload;
    },
    setForgetPasswordToken: (state, action) => {
      state.forgetPasswordToken = action.payload;
    },
    setAgoraToken: (state, action) => {
      state.agoraToken = action.payload;
    },
    setFCMToken: (state, action) => {
      state.fcmToken = action.payload;
    },
  },
});

export const {
  loginSuccess,
  setAgoraToken,
  setEmailforOtp,
  setForgetPasswordToken,
  socialLoginSuccess,
  setFCMToken,
} = authSlice.actions;

export default authSlice.reducer;
