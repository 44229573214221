const HoldSpotIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14.196"
    height="14.196"
    viewBox="0 0 14.196 14.196"
  >
    <g id="_2948239" data-name="2948239" transform="translate(0)">
      <path
        id="Path_117"
        data-name="Path 117"
        d="M12.366,1.109H11.091V.444a.444.444,0,0,0-.887,0v.665H3.993V.444a.444.444,0,0,0-.887,0v.665H1.83A1.832,1.832,0,0,0,0,2.939v9.427A1.832,1.832,0,0,0,1.83,14.2H12.366a1.832,1.832,0,0,0,1.83-1.83V2.939A1.832,1.832,0,0,0,12.366,1.109Zm.943,11.257a.943.943,0,0,1-.943.943H1.83a.943.943,0,0,1-.943-.943V5.019a.139.139,0,0,1,.139-.139H13.17a.139.139,0,0,1,.139.139Z"
        transform="translate(0)"
        fill="#fff"
      />
    </g>
  </svg>
);

export default HoldSpotIcon;
