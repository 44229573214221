const HeartActiveIcon = () => (
  <svg width="15.587" height="13.868" viewBox="0 0 15.587 13.868">
    <path
      id="_1077086"
      data-name="1077086"
      d="M14.351,1.357A4.191,4.191,0,0,0,11.234,0,3.921,3.921,0,0,0,8.784.845a5.011,5.011,0,0,0-.99,1.034A5.008,5.008,0,0,0,6.8.845,3.921,3.921,0,0,0,4.355,0,4.192,4.192,0,0,0,1.237,1.357,4.872,4.872,0,0,0,0,4.685a5.8,5.8,0,0,0,1.546,3.8,32.967,32.967,0,0,0,3.87,3.632c.536.457,1.144.975,1.775,1.527a.915.915,0,0,0,1.2,0c.631-.552,1.239-1.07,1.776-1.527a32.948,32.948,0,0,0,3.87-3.632,5.8,5.8,0,0,0,1.546-3.8,4.872,4.872,0,0,0-1.237-3.327Zm0,0"
      transform="translate(0)"
      fill="#afafaf"
    />
  </svg>
);

export default HeartActiveIcon;
