import React, { Fragment } from 'react';

const CircleIcon = () => {
  return (
    <Fragment>
      <svg xmlns="http://www.w3.org/2000/svg" width={4} height={18} viewBox="0 0 4 18">
        <g id="Group_41419" data-name="Group 41419" transform="translate(-1264 -501)">
          <circle
            id="Ellipse_355"
            data-name="Ellipse 355"
            cx={2}
            cy={2}
            r={2}
            transform="translate(1264 501)"
            fill="#a3a3a3"
          />
          <circle
            id="Ellipse_356"
            data-name="Ellipse 356"
            cx={2}
            cy={2}
            r={2}
            transform="translate(1264 508)"
            fill="#a3a3a3"
          />
          <circle
            id="Ellipse_357"
            data-name="Ellipse 357"
            cx={2}
            cy={2}
            r={2}
            transform="translate(1264 515)"
            fill="#a3a3a3"
          />
        </g>
      </svg>
    </Fragment>
  );
};

export default CircleIcon;
