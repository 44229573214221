const CameraIcon = () => (
  <svg width="29.857" height="23.5" viewBox="0 0 29.857 23.5">
    <g id="camera" transform="translate(0 0)">
      <g id="Group_37584" data-name="Group 37584" transform="translate(0 0)">
        <g id="Group_37583" data-name="Group 37583" transform="translate(0 0)">
          <path
            id="Path_36006"
            data-name="Path 36006"
            d="M28.864,48.461a3.21,3.21,0,0,0-2.356-.993H21.8v-.057a2.548,2.548,0,0,0-.766-1.845,2.6,2.6,0,0,0-1.845-.766H10.671a2.617,2.617,0,0,0-2.639,2.611v.057H3.349a3.21,3.21,0,0,0-2.356.993A3.374,3.374,0,0,0,0,50.817V64.951a3.21,3.21,0,0,0,.993,2.356,3.374,3.374,0,0,0,2.356.993H26.508a3.21,3.21,0,0,0,2.356-.993,3.374,3.374,0,0,0,.993-2.356V50.817A3.21,3.21,0,0,0,28.864,48.461Zm-.482,16.49h-.028A1.841,1.841,0,0,1,26.508,66.8H3.349A1.841,1.841,0,0,1,1.5,64.951V50.817a1.841,1.841,0,0,1,1.845-1.845H8.827a.763.763,0,0,0,.766-.766v-.823A1.068,1.068,0,0,1,10.7,46.276h8.486a1.068,1.068,0,0,1,1.107,1.107v.823a.763.763,0,0,0,.766.766h5.478a1.841,1.841,0,0,1,1.845,1.845Z"
            transform="translate(0 -44.8)"
            fill="#f66962"
          />
          <path
            id="Path_36007"
            data-name="Path 36007"
            d="M118.982,130.8a6.977,6.977,0,1,0,4.938,2.043A6.994,6.994,0,0,0,118.982,130.8Zm3.86,10.87a5.482,5.482,0,0,1-7.72,0,5.426,5.426,0,0,1-1.589-3.86,5.546,5.546,0,0,1,1.589-3.86,5.426,5.426,0,0,1,3.86-1.589,5.546,5.546,0,0,1,3.86,1.589,5.426,5.426,0,0,1,1.589,3.86A5.311,5.311,0,0,1,122.842,141.67Z"
            transform="translate(-104.053 -124.698)"
            fill="#f66962"
          />
          <circle
            id="Ellipse_389"
            data-name="Ellipse 389"
            cx="1.391"
            cy="1.391"
            r="1.391"
            transform="translate(23.642 6.074)"
            fill="#f66962"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default CameraIcon;
