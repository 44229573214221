const Download = () => (
  <svg width="15.938" height="17" viewBox="0 0 15.938 17">
    <g id="download" transform="translate(-16)">
      <g id="Group_37589" data-name="Group 37589" transform="translate(19.719)">
        <g id="Group_37588" data-name="Group 37588">
          <path
            id="Path_36013"
            data-name="Path 36013"
            d="M136.45,7.749a.53.53,0,0,0-.483-.311h-2.125V.531A.531.531,0,0,0,133.311,0h-2.125a.531.531,0,0,0-.531.531V7.438h-2.125a.531.531,0,0,0-.4.881l3.719,4.25a.53.53,0,0,0,.8,0l3.719-4.25A.53.53,0,0,0,136.45,7.749Z"
            transform="translate(-127.998)"
            fill="#fff"
          />
        </g>
      </g>
      <g id="Group_37591" data-name="Group 37591" transform="translate(16 11.688)">
        <g id="Group_37590" data-name="Group 37590">
          <path
            id="Path_36014"
            data-name="Path 36014"
            d="M29.813,352v3.188H18.125V352H16v4.25a1.062,1.062,0,0,0,1.063,1.062H30.875a1.061,1.061,0,0,0,1.063-1.062V352Z"
            transform="translate(-16 -352)"
            fill="#fff"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default Download;
