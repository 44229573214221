import * as React from 'react';

const EyeSlashIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={17.039} height={15.347} {...props}>
    <g data-name="Group 41428">
      <g opacity={0.56}>
        <path
          data-name="Path 25285"
          d="M8.52 2.484c5.153 0 8.52 4.88 8.52 4.88s-3.366 4.88-8.52 4.88S0 7.364 0 7.364s3.366-4.88 8.52-4.88Zm0 1.394a3.644 3.644 0 0 1 3.786 3.486A3.644 3.644 0 0 1 8.52 10.85a3.644 3.644 0 0 1-3.787-3.486 3.644 3.644 0 0 1 3.786-3.486Zm0 1.569a1.924 1.924 0 1 1 0 3.834 1.924 1.924 0 1 1 0-3.834Z"
          fillRule="evenodd"
          opacity={0.61}
        />
      </g>
      <g data-name="Path 43964" fill="#a7a7a7">
        <path d="m4.454 15.076-1.342-.872L12.159.277l1.342.872-9.047 13.927Z" />
        <path
          d="m12.218.554-8.83 13.591 1.007.654 8.83-13.591-1.007-.654M12.1 0l1.678 1.09-9.265 14.263-1.678-1.09L12.1 0Z"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
);

export default EyeSlashIcon;
