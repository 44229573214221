const TranslateIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="145" height="145" viewBox="0 0 145 145">
    <g id="Group_41546" data-name="Group 41546" transform="translate(-40)">
      <rect
        id="Rectangle_5241"
        data-name="Rectangle 5241"
        width="145"
        height="145"
        rx="72.5"
        transform="translate(40)"
        fill="#fff"
      />
      <g
        id="Rectangle_5240"
        data-name="Rectangle 5240"
        transform="translate(51 11)"
        fill="#fff"
        stroke="#414143"
        strokeWidth="1"
      >
        <rect width="124" height="124" rx="62" stroke="none" />
        <rect x="0.5" y="0.5" width="123" height="123" rx="61.5" fill="none" />
      </g>
      <g
        id="_692155_translate_alteration_language_line-icon_logos_icon"
        data-name="692155_translate_alteration_language_line-icon_logos_icon"
        transform="translate(82.781 42.781)"
      >
        <g id="Group_41394" data-name="Group 41394">
          <path
            id="Path_43704"
            data-name="Path 43704"
            d="M44.315,52.135H6.517A6.526,6.526,0,0,1,0,45.618V6.517A6.526,6.526,0,0,1,6.517,0H27.371A1.3,1.3,0,0,1,28.6.881L45.548,50.41a1.3,1.3,0,0,1-1.233,1.726ZM6.517,2.607a3.915,3.915,0,0,0-3.91,3.91v39.1a3.915,3.915,0,0,0,3.91,3.91H42.49L26.44,2.607Z"
            transform="translate(0 0)"
            fill="#414143"
          />
        </g>
        <g id="Group_41395" data-name="Group 41395" transform="translate(29.978 10.427)">
          <path
            id="Path_43705"
            data-name="Path 43705"
            d="M37.568,56.135H16.714a1.3,1.3,0,0,1-1.22-.845l-3.91-10.427a1.3,1.3,0,1,1,2.44-.918l3.595,9.582h19.95a3.915,3.915,0,0,0,3.91-3.91v-39.1a3.915,3.915,0,0,0-3.91-3.91H12.8A1.3,1.3,0,0,1,12.8,4H37.568a6.526,6.526,0,0,1,6.517,6.517v39.1A6.526,6.526,0,0,1,37.568,56.135Z"
            transform="translate(-11.5 -4)"
            fill="#414143"
          />
        </g>
        <g id="Group_41396" data-name="Group 41396" transform="translate(33.888 49.526)">
          <path
            id="Path_43706"
            data-name="Path 43706"
            d="M14.3,32.036a1.3,1.3,0,0,1-.98-2.164l9.124-10.427a1.3,1.3,0,0,1,1.963,1.718L15.286,31.59A1.309,1.309,0,0,1,14.3,32.036Z"
            transform="translate(-13 -18.999)"
            fill="#414143"
          />
        </g>
        <g id="Group_41397" data-name="Group 41397" transform="translate(10.426 15.728)">
          <path
            id="Path_43707"
            data-name="Path 43707"
            d="M18.338,26.8a1.3,1.3,0,0,1-1.228-.865L11.821,11.126,6.532,25.935a1.3,1.3,0,1,1-2.456-.876L10.593,6.812a1.356,1.356,0,0,1,2.453,0l6.517,18.247a1.3,1.3,0,0,1-.79,1.666A1.255,1.255,0,0,1,18.338,26.8Z"
            transform="translate(-4 -6.034)"
            fill="#414143"
          />
        </g>
        <g id="Group_41398" data-name="Group 41398" transform="translate(14.337 26.068)">
          <path
            id="Path_43708"
            data-name="Path 43708"
            d="M12.017,12.607H6.8A1.3,1.3,0,0,1,6.8,10h5.214a1.3,1.3,0,1,1,0,2.607Z"
            transform="translate(-5.5 -10)"
            fill="#414143"
          />
        </g>
        <g id="Group_41399" data-name="Group 41399" transform="translate(36.495 26.068)">
          <path
            id="Path_43709"
            data-name="Path 43709"
            d="M33.551,12.607H15.3A1.3,1.3,0,1,1,15.3,10H33.551a1.3,1.3,0,1,1,0,2.607Z"
            transform="translate(-14 -10)"
            fill="#414143"
          />
        </g>
        <g id="Group_41400" data-name="Group 41400" transform="translate(44.315 23.461)">
          <path
            id="Path_43710"
            data-name="Path 43710"
            d="M18.3,14.214a1.3,1.3,0,0,1-1.3-1.3V10.3a1.3,1.3,0,1,1,2.607,0V12.91A1.3,1.3,0,0,1,18.3,14.214Z"
            transform="translate(-17 -9)"
            fill="#414143"
          />
        </g>
        <g id="Group_41401" data-name="Group 41401" transform="translate(40.404 26.07)">
          <path
            id="Path_43711"
            data-name="Path 43711"
            d="M16.8,28.246a1.3,1.3,0,0,1-.756-2.364c5.706-4.054,9.88-12.322,9.88-14.577a1.3,1.3,0,1,1,2.607,0c0,3.436-5.023,12.473-10.975,16.7A1.323,1.323,0,0,1,16.8,28.246Z"
            transform="translate(-15.5 -10.001)"
            fill="#414143"
          />
        </g>
        <g id="Group_41402" data-name="Group 41402" transform="translate(40.405 32.586)">
          <path
            id="Path_43712"
            data-name="Path 43712"
            d="M27.23,26.837a1.287,1.287,0,0,1-.878-.341c-.946-.865-9.275-8.558-10.756-12.2a1.3,1.3,0,0,1,2.414-.98c1.066,2.625,7.653,9.017,10.1,11.259a1.3,1.3,0,0,1-.881,2.265Z"
            transform="translate(-15.5 -12.5)"
            fill="#414143"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default TranslateIcon;
