const AppleIcon = () => (
  <svg
    id="apple_4_"
    data-name="apple (4)"
    width="13.695"
    height="16.696"
    viewBox="0 0 13.695 16.696"
  >
    <path
      id="Path_28738"
      data-name="Path 28738"
      d="M50.562,130.808c-2.485-.014-4.54-5.087-4.54-7.67,0-4.22,3.166-5.144,4.386-5.144a5.046,5.046,0,0,1,1.655.407,3.7,3.7,0,0,0,.945.271,2.94,2.94,0,0,0,.678-.22,5.433,5.433,0,0,1,2.043-.494h0a4.188,4.188,0,0,1,3.5,1.77l.256.384-.368.278a3.1,3.1,0,0,0-1.484,2.552,2.881,2.881,0,0,0,1.607,2.663c.23.139.469.281.469.594,0,.2-1.628,4.584-3.992,4.584a3.21,3.21,0,0,1-1.348-.327,2.733,2.733,0,0,0-1.2-.289,3.086,3.086,0,0,0-.95.257,4.654,4.654,0,0,1-1.648.384Z"
      transform="translate(-46.022 -114.111)"
      fill="#fff"
    ></path>
    <path
      id="Path_28739"
      data-name="Path 28739"
      d="M258.291,0c.062,2.22-1.526,3.76-3.112,3.664A3.538,3.538,0,0,1,258.291,0Z"
      transform="translate(-248.334)"
      fill="#fff"
    ></path>
  </svg>
);

export default AppleIcon;
