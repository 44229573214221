const TeachingVideoIcon = () => (
  <svg className="stroke_svg" width="17.854" height="24.158" viewBox="0 0 17.854 24.158">
    <g id="audiobook" transform="translate(-68.2 0.3)">
      <path
        id="Path_35981"
        data-name="Path 35981"
        d="M83.8,0H68.5V23.558H79.888v-.69H71.951V4.155h-.69V22.867H69.19V.69h2.07V3.474h.69V.69H80.8V4.8h.87l.516-.757.508.757h.866V.69l1.5,1.5V21.364l-1.543,1.5H81.958v.69H83.8l1.953-1.9V1.9Zm-.93,3.825-.68-1.013-.7,1.027V.69h1.38Z"
        fill="#005182"
        stroke="#2c6384"
        strokeWidth="0.6"
      />
      <path
        id="Path_35982"
        data-name="Path 35982"
        d="M331,497h.69v.69H331Z"
        transform="translate(-250.422 -474.133)"
        fill="#005182"
        stroke="#2c6384"
        strokeWidth="0.6"
      />
      <path
        id="Path_35983"
        data-name="Path 35983"
        d="M176.092,153.163a5.184,5.184,0,1,0-.682-.47l.436-.535a4.47,4.47,0,1,1,.591.408Z"
        transform="translate(-100.169 -136.898)"
        fill="#005182"
        stroke="#2c6384"
        strokeWidth="0.6"
      />
      <path
        id="Path_35984"
        data-name="Path 35984"
        d="M305.2,176.838l-.194.662a3.094,3.094,0,0,1,.574.233l.322-.61A3.787,3.787,0,0,0,305.2,176.838Z"
        transform="translate(-225.629 -168.701)"
        fill="#005182"
        stroke="#2c6384"
        strokeWidth="0.6"
      />
      <path
        id="Path_35985"
        data-name="Path 35985"
        d="M329.7,192.667a3.775,3.775,0,0,0-.536-.536l-.436.535a3.085,3.085,0,0,1,.438.438Z"
        transform="translate(-248.255 -183.291)"
        fill="#005182"
        stroke="#2c6384"
        strokeWidth="0.6"
      />
      <path
        id="Path_35986"
        data-name="Path 35986"
        d="M248.5,211.155l4.545-2.272L248.5,206.61Zm.69-3.428,2.312,1.156-2.312,1.156Z"
        transform="translate(-171.717 -197.104)"
        fill="#005182"
        stroke="#2c6384"
        strokeWidth="0.6"
      />
      <path
        id="Path_35987"
        data-name="Path 35987"
        d="M158.5,30.5h.69v.69h-.69Z"
        transform="translate(-85.859 -29.099)"
        fill="#005182"
        stroke="#2c6384"
        strokeWidth="0.6"
      />
      <path
        id="Path_35988"
        data-name="Path 35988"
        d="M188.5,30.5h.69v.69h-.69Z"
        transform="translate(-114.479 -29.099)"
        fill="#005182"
        stroke="#2c6384"
        strokeWidth="0.6"
      />
    </g>
  </svg>
);

export default TeachingVideoIcon;
