import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  schoolInfo: {},
  bankRes: {},
  schoolList: '',
  schoolListFilter: '',
  school_details: [],
  school_student_details: {},
  all_student: [],
  all_School_Courses: [],
  school_plan: [],
  subscription_plan_purchase_details: {},
  single_School_Courses_Detail: '',
  school_class_reservation_detail: [],
  upcoming_classes: [],
  school_student_class_detail: [],
  school_student_enroll_course: [],
  school_student_enroll_course_detail: {},
  enrollStatus: '',
  mySchoolCourses: '',
  callAudioEnable: true,
  callVideoEnable: true,
  call_disconnect: false,
  allSchoolStuds: '',
};

const schoolSlice = createSlice({
  name: 'school',
  initialState,
  reducers: {
    setSchoolInfo: (state, action) => {
      state.schoolInfo = action.payload;
    },
    setSchBankInfo: (state, action) => {
      state.bankRes = action.payload;
    },
    setSchoolList: (state, action) => {
      state.schoolList = action.payload;
    },
    setSchoolListFilter: (state, action) => {
      state.schoolListFilter = action.payload;
    },
    school_details: (state, action) => {
      state.school_details = action.payload;
    },
    school_student_details: (state, action) => {
      state.school_student_details = action.payload;
    },

    all_student: (state, action) => {
      state.all_student = action.payload;
    },
    all_School_Courses_Success: (state, action) => {
      state.all_School_Courses = action.payload;
    },

    school_plan: (state, action) => {
      state.school_plan = action.payload;
    },

    subscription_plan_purchase_details: (state, action) => {
      state.subscription_plan_purchase_details = action.payload;
    },

    single_School_CoursesDetail_Success: (state, action) => {
      state.single_School_Courses_Detail = action.payload;
    },
    school_class_reservation_detail: (state, action) => {
      state.school_class_reservation_detail = action.payload;
    },
    upcoming_classes: (state, action) => {
      state.upcoming_classes = action.payload;
    },
    school_student_class_detail: (state, action) => {
      state.school_student_class_detail = action.payload;
    },
    school_student_enroll_course: (state, action) => {
      state.school_student_enroll_course = action.payload;
    },
    school_student_enroll_course_detail: (state, action) => {
      state.school_student_enroll_course_detail = action.payload;
    },
    enrollStatusSchool: (state, action) => {
      state.enrollStatus = action.payload;
    },
    setMySchoolCourses: (state, action) => {
      state.mySchoolCourses = action.payload;
    },
    callAudioEnable: (state, action) => {
      state.callAudioEnable = action.payload;
    },
    callVideoEnable: (state, action) => {
      state.callVideoEnable = action.payload;
    },
    call_disconnect: (state, action) => {
      state.call_disconnect = action.payload;
    },
    setSchoolStudentsData: (state, action) => {
      state.allSchoolStuds = action.payload;
    },
  },
});

export const {
  setSchoolInfo,
  all_School_Courses_Success,
  setSchBankInfo,
  setSchoolList,
  setSchoolListFilter,
  school_details,
  school_student_details,
  all_student,
  school_plan,
  subscription_plan_purchase_details,
  single_School_CoursesDetail_Success,
  school_class_reservation_detail,
  upcoming_classes,
  school_student_class_detail,
  school_student_enroll_course,
  school_student_enroll_course_detail,
  enrollStatusSchool,
  setMySchoolCourses,
  callAudioEnable,
  callVideoEnable,
  call_disconnect,
  setSchoolStudentsData,
} = schoolSlice.actions;

export default schoolSlice.reducer;
