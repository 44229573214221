const CalendarHomePageIcon = () => (
  <svg
    id="_2948239"
    data-name="2948239"
    xmlns="http://www.w3.org/2000/svg"
    width="12.304"
    height="12.304"
    viewBox="0 0 12.304 12.304"
  >
    <path
      id="Path_117"
      data-name="Path 117"
      d="M10.718.961H9.612V.384a.384.384,0,0,0-.769,0V.961H3.46V.384a.384.384,0,1,0-.769,0V.961H1.586A1.588,1.588,0,0,0,0,2.547v8.17A1.588,1.588,0,0,0,1.586,12.3h9.132A1.588,1.588,0,0,0,12.3,10.718V2.547A1.588,1.588,0,0,0,10.718.961Zm.817,9.756a.817.817,0,0,1-.817.817H1.586a.817.817,0,0,1-.817-.817V4.35a.12.12,0,0,1,.12-.12H11.415a.12.12,0,0,1,.12.12Z"
      transform="translate(0)"
      fill="#0a9ef8"
    ></path>
  </svg>
);

export default CalendarHomePageIcon;
