const ReferralCode = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15.221"
        height="15.221"
        viewBox="0 0 15.221 15.221"
      >
        <g id="tag_1_" data-name="tag (1)" transform="translate(0.1 0.094)">
          <g id="Group_37206" data-name="Group 37206" transform="translate(0 0.006)">
            <g id="Group_37205" data-name="Group 37205" transform="translate(0 0)">
              <path
                id="Path_28795"
                data-name="Path 28795"
                d="M15.021,1.029A1.018,1.018,0,0,0,14,.006a1.025,1.025,0,0,0-.725.3c-.082.082-.232.26-.313.358a.243.243,0,0,0,.374.311c.128-.154.234-.276.283-.325A.542.542,0,0,1,14,.492h0a.531.531,0,0,1,.532.534.542.542,0,0,1-.16.383,8.7,8.7,0,0,1-.86.658A1.542,1.542,0,0,0,12.184,1.3H8.62a1.789,1.789,0,0,0-1.273.527L.223,8.953a.762.762,0,0,0,0,1.078L5,14.8a.763.763,0,0,0,1.078,0L13.2,7.681a1.789,1.789,0,0,0,.527-1.273V2.844a1.541,1.541,0,0,0-.031-.307,9.314,9.314,0,0,0,1.025-.783A1.025,1.025,0,0,0,15.021,1.029ZM13.238,6.407a1.306,1.306,0,0,1-.385.929L5.73,14.46a.276.276,0,0,1-.39,0L.568,9.687a.276.276,0,0,1,0-.39L7.691,2.174a1.306,1.306,0,0,1,.929-.385h3.564a1.055,1.055,0,0,1,.928.553c-.287.19-.609.393-.951.592L12.128,2.9a1.023,1.023,0,1,0,.265.462c.3-.173.582-.35.845-.521V6.407ZM11.187,3.732a.243.243,0,0,0,.327.108q.214-.108.426-.223a.536.536,0,1,1-.226-.431q-.207.112-.418.219A.243.243,0,0,0,11.187,3.732Z"
                transform="translate(0 -0.006)"
                fill="#005182"
                stroke="#005182"
                strokeWidth="0.2"
              />
            </g>
          </g>
          <g id="Group_37208" data-name="Group 37208" transform="translate(7.008 4.963)">
            <g id="Group_37207" data-name="Group 37207">
              <path
                id="Path_28796"
                data-name="Path 28796"
                d="M239.11,168.962a.243.243,0,0,0-.243.243v5.14a.243.243,0,1,0,.487,0v-5.14A.243.243,0,0,0,239.11,168.962Z"
                transform="translate(-238.867 -168.962)"
                fill="#005182"
                stroke="#005182"
                strokeWidth="0.2"
              />
            </g>
          </g>
          <g id="Group_37210" data-name="Group 37210" transform="translate(4.394 6.754)">
            <g id="Group_37209" data-name="Group 37209">
              <path
                id="Path_28797"
                data-name="Path 28797"
                d="M151.519,230.323a1.022,1.022,0,1,0,.3.723A1.023,1.023,0,0,0,151.519,230.323Zm-.344,1.1a.535.535,0,1,1,.157-.379A.536.536,0,0,1,151.175,231.424Z"
                transform="translate(-149.775 -230.024)"
                fill="#005182"
                stroke="#005182"
                strokeWidth="0.2"
              />
            </g>
          </g>
          <g id="Group_37212" data-name="Group 37212" transform="translate(8.065 6.754)">
            <g id="Group_37211" data-name="Group 37211">
              <path
                id="Path_28798"
                data-name="Path 28798"
                d="M276.648,230.323a1.022,1.022,0,1,0,0,1.445A1.023,1.023,0,0,0,276.648,230.323Zm-.344,1.1a.535.535,0,1,1,0-.757A.536.536,0,0,1,276.3,231.424Z"
                transform="translate(-274.904 -230.024)"
                fill="#005182"
                stroke="#005182"
                strokeWidth="0.2"
              />
            </g>
          </g>
        </g>
      </svg>
    </>
  );
};

export default ReferralCode;
