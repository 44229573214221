const ListCircleIcon = () => (
  <svg width="14" height="14" viewBox="0 0 14 14">
    <g
      id="Rectangle_4690"
      data-name="Rectangle 4690"
      transform="translate(3 3)"
      fill="#009ff4"
      stroke="rgba(0,159,244,0.15)"
      strokeWidth="3"
    >
      <rect width="8" height="8" rx="4" stroke="none" />
      <rect x="-1.5" y="-1.5" width="11" height="11" rx="5.5" fill="none" />
    </g>
  </svg>
);

export default ListCircleIcon;
