const EditpenTeacherHistoryIcon = () => (
  <svg width="22.091" height="22.28" viewBox="0 0 22.091 22.28">
    <g id="Icon_feather-edit" data-name="Icon feather-edit" transform="translate(-2 -1.629)">
      <path
        id="Path_43702"
        data-name="Path 43702"
        d="M11.986,6H5A2,2,0,0,0,3,8V21.976a2,2,0,0,0,2,2H18.976a2,2,0,0,0,2-2V14.986"
        transform="translate(0 -1.064)"
        fill="none"
        stroke="#f66962"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      ></path>
      <path
        id="Path_43703"
        data-name="Path 43703"
        d="M22.484,3.438a2.118,2.118,0,0,1,3,3L15.994,15.92l-3.994,1,1-3.994Z"
        transform="translate(-3.009)"
        fill="none"
        stroke="#f66962"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      ></path>
    </g>
  </svg>
);

export default EditpenTeacherHistoryIcon;
