const SendMessageIcon = () => {
  return (
    <svg width="13.996" height="13.997" viewBox="0 0 13.996 13.997">
      <path
        id="email_5_"
        data-name="email (5)"
        d="M14.334.342A.781.781,0,0,0,14.256.25l0,0,0,0A.88.88,0,0,0,13.615,0a1.466,1.466,0,0,0-.462.081L1.258,4.046c-.629.21-.744.576-.756.776s.056.577.655.863L4.143,7.111l-1.337,3.3-.009.024a1.041,1.041,0,0,0,.164,1.1.879.879,0,0,0,.639.244,1.463,1.463,0,0,0,.46-.08l.024-.009,3.3-1.337,1.426,2.987c.273.571.632.656.822.656.17,0,.589-.074.817-.758L14.416,1.344A1.094,1.094,0,0,0,14.334.342ZM1.389,4.877a.955.955,0,0,1,.129-.053L12.881,1.036,6.47,7.447a3.451,3.451,0,0,0-.71-.473L1.51,4.945A.965.965,0,0,1,1.389,4.877Zm2.4,6.049a.455.455,0,0,1-.247.029.455.455,0,0,1,.029-.247L4.885,7.466l.521.249A3.514,3.514,0,0,1,6.783,9.091l.249.522ZM9.673,12.98a.949.949,0,0,1-.053.129.939.939,0,0,1-.068-.121l-2.03-4.25a3.454,3.454,0,0,0-.473-.71l6.411-6.411Zm0,0"
        transform="translate(-0.501)"
        fill="#fff"
      ></path>
    </svg>
  );
};

export default SendMessageIcon;
