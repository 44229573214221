import { Fragment } from 'react';

const VideoIcon = () => {
  return (
    <Fragment>
      <svg className="me-2" width="22.801" height="14.738" viewBox="0 0 22.801 14.738">
        <path
          id="video-camera"
          d="M21.575,1.225a.462.462,0,0,0-.457-.008l-5,2.731V2.309A2.312,2.312,0,0,0,13.806,0H2.309A2.312,2.312,0,0,0,0,2.309v9.119a2.312,2.312,0,0,0,2.309,2.309h11.5a2.312,2.312,0,0,0,2.309-2.309V9.819l5,2.731a.462.462,0,0,0,.683-.405V1.622A.462.462,0,0,0,21.575,1.225Zm-6.383,10.2a1.387,1.387,0,0,1-1.386,1.386H2.309A1.387,1.387,0,0,1,.924,11.428V2.309A1.387,1.387,0,0,1,2.309.924h11.5a1.387,1.387,0,0,1,1.386,1.386Zm5.686-.062-4.762-2.6V5l4.762-2.6Zm0,0"
          transform="translate(0.5 0.5)"
          fill="#f66962"
          stroke="#f66962"
          strokeWidth="1"
        />
      </svg>
    </Fragment>
  );
};

export default VideoIcon;
