const RemoveIcon = () => (
  <svg width="14.839" height="16.332" viewBox="0 0 14.839 16.332">
    <g id="Icon_feather-trash-2" data-name="Icon feather-trash-2" transform="translate(-3.8 -2.3)">
      <path
        id="Path_36036"
        data-name="Path 36036"
        d="M4.5,9H17.938"
        transform="translate(0 -3.014)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.4"
      />
      <path
        id="Path_36037"
        data-name="Path 36037"
        d="M17.952,5.986V16.438a1.493,1.493,0,0,1-1.493,1.493H8.993A1.493,1.493,0,0,1,7.5,16.438V5.986m2.24,0V4.493A1.493,1.493,0,0,1,11.233,3h2.986a1.493,1.493,0,0,1,1.493,1.493V5.986"
        transform="translate(-1.507 0)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.4"
      />
      <path
        id="Path_36038"
        data-name="Path 36038"
        d="M15,16.5v4.479"
        transform="translate(-5.274 -6.781)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.4"
      />
      <path
        id="Path_36039"
        data-name="Path 36039"
        d="M21,16.5v4.479"
        transform="translate(-8.288 -6.781)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.4"
      />
    </g>
  </svg>
);

export default RemoveIcon;
